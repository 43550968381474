<template>
  <div>
    <div class="pa-0 ma-0">
      <v-row class="d-none">
        <v-col class="pt-6">
          <input ref="input_xlsx" type="file" accept=".xls,.xlsx" @change="uploadXLS" />
        </v-col>
      </v-row>

      <!-- Smart fields-->
      <v-card :class="$route.fullPath.includes('skueditor') ? 'sticky' : ''" flat>
        <!-- Row Info -->
        <v-card-text v-if="$route.fullPath.includes('skueditor')" class="py-1 px-0">
          <p class="info white--text text-center my-0" data-test="rowsPerPageInfo">{{ totalDisplayedRows }} / {{ skus.length }} {{ swT('rowsselected') }}</p>
        </v-card-text>

        <!-- Field selection -->
        <v-card-text class="py-1 px-1">
          <v-row>
            <v-col cols="10">
              <v-combobox
                v-model="uiSelectedFields"
                :items="uiFieldChoices"
                multiple
                chips
                outlined
                dense
                hide-selected
                auto-select-first
                autofocus
                :label="swT('selectfields')"
                hide-details
                data-test="select-fields"
                :disabled="fieldsDisabled"
                :item-disabled="isUISelectedFieldDisabled"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="data.item.value"
                    v-bind="data.attrs"
                    :close="canBeRemoved(data.item.value)"
                    small
                    class="white--text mt-1"
                    :color="setChipColor(data.item)"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="removeSelectedField(data.item)"
                    @click.stop="selectColumnByName(data.item.value)"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                hide-details
                dense
                :value="totalRowsOnPage"
                :label="swT('rowsperpage')"
                type="number"
                min="1"
                class="ml-4"
                max="1000"
                :data-test="'rowsPerPage'"
                @input="totalRowsOnPage = Math.max(1, parseInt($event, 10) || 1)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Dynamic fields -->
        <v-card-text class="px-3 pb-3">
          <v-row v-if="editField && !masterdata" class="d-flex py-1">
            <v-col v-if="!readonly" class="py-0 px-1 flex-grow-1">
              <v-select
                v-show="fields[selectedField].type === 'enum'"
                v-model="fieldLevelReplace[selectedField]"
                :items="translateEnumItems"
                class="py-0 my-0 green-border green lighten-5"
                :data-test="`autocomplete-${selectedField}`"
                hide-details
                dense
                :menu-props="{ offsetY: true, class: 'px-5' }"
                outlined
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
              ></v-select>
              <v-combobox
                v-show="fields[selectedField].type === 'combobox'"
                v-model="fieldLevelReplace[selectedField]"
                :items="fields[selectedField].items"
                class="py-0 my-0 green-border green lighten-5"
                :data-test="`combobox-${selectedField}`"
                hide-details
                dense
                :menu-props="{ offsetY: true, class: 'px-5' }"
                outlined
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
                @blur="cb($event, selectedField)"
              ></v-combobox>
              <v-switch
                v-show="fields[selectedField].type === 'boolean'"
                v-model="fieldLevelReplace[selectedField]"
                class="py-2 my-0 pl-2 green-border green lighten-5"
                :data-test="`switch-${selectedField}`"
                hide-details
                outlined
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
              ></v-switch>
              <v-select
                v-show="fields[selectedField].type === 'multiselect'"
                v-model="fieldLevelReplace[selectedField]"
                :items="fields[selectedField].items"
                class="py-0 my-0 green-border green lighten-5"
                :data-test="`multiselect-${selectedField}`"
                hide-details
                dense
                :menu-props="{ offsetY: true, class: 'px-5' }"
                outlined
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
                multiple
              ></v-select>
              <v-text-field
                v-show="fields[selectedField].type === 'text'"
                v-model="fieldLevelReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`replace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
              ></v-text-field>
              <v-text-field
                v-show="fields[selectedField].type === 'number'"
                v-model="fieldLevelReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`replace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                type="number"
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
              ></v-text-field>
              <v-text-field
                v-show="fields[selectedField].type === 'currency'"
                v-model="fieldLevelReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`replace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT(fields[selectedField].value)"
              ></v-text-field>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly && fields[selectedField].type === 'currency'" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-text-field
                v-show="fields[selectedField].type === 'currency'"
                v-model="fieldLevelMinPercentReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`percentminreplace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT('markdown')"
                append-icon="mdi-percent"
                style="width: 120px"
                @click="showSalePricePopup($event, selectedField)"
                @input="menuActivated = false"
              ></v-text-field>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly && fields[selectedField].type === 'currency'" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-text-field
                v-show="fields[selectedField].type === 'currency'"
                v-model="fieldLevelPlusPercentReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`percentplusreplace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT('markup')"
                append-icon="mdi-percent"
                style="width: 120px"
                @click="showSalePricePopup($event, selectedField)"
                @input="menuActivated = false"
              ></v-text-field>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly && fields[selectedField].type === 'currency'" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-text-field
                v-show="fields[selectedField].type === 'currency'"
                v-model="fieldLevelMinValueReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`valueminreplace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT('markdown')"
                append-icon="mdi-minus"
                style="width: 120px"
              ></v-text-field>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly && fields[selectedField].type === 'currency'" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-text-field
                v-show="fields[selectedField].type === 'currency'"
                v-model="fieldLevelPlusValueReplace[selectedField]"
                :class="!fields[selectedField].readOnly ? 'green-border green lighten-5' : 'orange-border orange lighten-5'"
                hide-details
                :data-test="`valueplusreplace-${selectedField}`"
                outlined
                dense
                persistent-placeholder
                :disabled="fields[selectedField].readOnly"
                :label="swT('markup')"
                append-icon="mdi-plus"
                style="width: 120px"
              ></v-text-field>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-btn v-if="$vuetify.breakpoint.smAndUp" color="success" height="40px" data-test="replaceButton" @click="replaceField(selectedField)">
                <v-icon>mdi-find-replace</v-icon>
                {{ swT('replace') }}
              </v-btn>
              <v-icon v-else class="pt-2" @click="replaceField(selectedField)">mdi-find-replace</v-icon>
            </v-col>
            <v-col v-if="!readonly && !fields[selectedField].readOnly" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-btn v-if="$vuetify.breakpoint.smAndUp" color="info" height="40px" data-test="clearButton" @click="clearField(selectedField)">
                <v-icon>mdi-backspace-outline</v-icon>
                {{ swT('clear') }}
              </v-btn>
              <v-icon v-else class="pt-2" @click="clearField(selectedField)">mdi-backspace-outline</v-icon>
            </v-col>
            <v-col v-if="!readonly && fields[selectedField].readOnly" class="pa-0 pl-2 flex-grow-0" align="right">
              <v-btn v-if="$vuetify.breakpoint.smAndUp" color="warning" height="40px" disabled>
                {{ swT('readonly') }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Filters -->
          <v-row class="white--text">
            <v-col v-for="(field, index) of selectedFields" :key="field" class="py-1 px-1">
              <v-text-field
                v-model="fieldLevelFindDebounced[field]"
                hide-details
                :class="setFilterClass(index)"
                outlined
                dense
                clearable
                label="filter"
                :data-test="`find-${field}`"
                persistent-placeholder
                @focus="selectedField = selectedFields[index]"
              ></v-text-field>
            </v-col>
            <v-col v-if="multiRowSelect" class="ma-0 pa-0 d-flex justify-center align-center flex-column" style="width: 24px; max-width: 24px">
              <v-icon data-test="filter-selected" dense hide-details class="mt-0 mb-0 pa-0" :color="filterSelected ? 'blue' : ''" @click="filterManualSelection">
                {{ filterSelected ? 'mdi-filter-minus' : 'mdi-filter-plus-outline' }}
              </v-icon>
            </v-col>
          </v-row>

          <!-- Table Header -->
          <v-row class="font-weight-bold white--text pt-1 d-flex mt-2">
            <draggable v-model="selectedFields" class="d-flex justify-space-between" style="width: 100%">
              <v-col v-for="(field, index) of selectedFields" :key="field" :class="setHeaderClass(index)" @click="cellHeaderClick({ selectedFields, index, fields })">
                <v-icon v-if="fields[field].sort === 'asc'" x-small right class="pa-0 ma-0">mdi-sort-alphabetical-ascending-variant</v-icon>
                <v-icon v-if="fields[field].sort === 'desc'" x-small right class="pa-0 ma-0">mdi-sort-alphabetical-descending-variant</v-icon>
                <v-icon v-if="fields[field].sort === undefined && selectedField === selectedFields[index]" x-small right class="pa-0 ma-0">mdi-sort-alphabetical-variant</v-icon>
                {{ swT(fields[field].text) }}
              </v-col>
              <v-col v-if="multiRowSelect" class="ma-0 pa-0" :class="setHeaderClass(999)" style="max-width: 24px; height: 30px">
                <v-checkbox
                  v-if="!isManualSelectionActive"
                  v-model="selectAll"
                  data-test="select-all-rows"
                  dense
                  hide-details
                  class="pa-0"
                  style="width: 24px; max-width: 24px; margin-top:-2px; margin-left:-5px"
                  @click="toggleAll"
                ></v-checkbox>
              </v-col>
            </draggable>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row v-if="loadingStatus && !$store.state.editing" class=" d-flex justify-center align-center">
        <v-card flat class="px-0">
          <v-img src="../assets/loading/Loading_Barcode.gif" cover height="400" width="400"></v-img>
        </v-card>
      </v-row>

      <!-- Data table -->
      <v-card v-else style="border-top: 1px solid #aaa; border-radius: inherit" elevation="0">
        <v-card-text class="py-0 px-0">
          <div style="border-left: 1px solid #aaa">
            <div v-for="(sku, index) in filteredSkus.slice((page - 1) * totalRowsOnPage, page * totalRowsOnPage)" :key="index">
              <v-row v-if="skuSyncDiff(sku, index)" :class="rowClass(index, sku)" class="ma-0 pa-0">
                <v-btn tile :color="skuSource(sku)" class="pa-0" style="position: absolute; min-height: 12px; height: 22px; min-width: 16px; filter: opacity(0.35)">
                  <v-icon small style="position: absolute; padding: 3px 0px; max-width: 16px" :data-test="`activate-menu-${sku.id}`" @click="activateMenu($event, sku)">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
                <v-col v-for="field of selectedFields" :key="field" class="py-0 ellipsis" :class="cellClass(sku, field, index)" @click.right="activateMenu($event, sku)">
                  <span v-if="displayDifferences(sku, field)">
                    <span class="text-decoration-line-through red--text">
                      <swSpanCurrency v-if="fields[field].type == 'currency'" :amount="originalSkuFormatter(sku, field)" />
                      <span v-else-if="fields[field].type == 'boolean'">{{ originalSkuFormatter(sku, field) ? '✅' : '❌' }}</span>
                      <span v-else class="text-truncate">{{ originalSkuFormatter(sku, field) }}</span>
                    </span>
                    &nbsp;
                    <span class="green--text font-weight-bold">
                      <swSpanCurrency v-if="fields[field].type == 'currency'" :amount="sku[fields[field].field]" />
                      <span v-else-if="fields[field].type == 'boolean'">{{ sku[fields[field].field] ? '✅' : '❌' }}</span>
                      <span v-else class="text-truncate">{{ cellFormatter(sku, field) }}</span>
                    </span>
                  </span>
                  <span v-else>
                    <swCurrency v-if="fields[field].type == 'currency'" :amount="sku[fields[field].field]" />
                    <div v-else-if="fields[field].type == 'boolean'">{{ sku[fields[field].field] ? '✅' : '❌' }}</div>
                    <div v-else-if="fields[field].type == 'image'" class="d-flex justify-space-between">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-if="cellFormatter(sku, field) > -1" dark color="green" dense small v-bind="attrs" v-on="on">mdi-eye</v-icon>
                        </template>

                        <v-slide-group v-model="slideGroupModel" center-active show-arrows>
                          <v-slide-item v-for="image in skuImages(sku)" :key="image" v-slot="{ active, toggle }">
                            <v-card text class="mx-1 d-flex align-center" height="200" width="100" color="transparent" @click="toggle">
                              <v-scale-transition>
                                <v-img :src="imageSrc(image, 150)" contain></v-img>
                              </v-scale-transition>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>
                      </v-tooltip>

                      {{ cellFormatter(sku, field) }}
                    </div>
                    <div v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="text-truncate" :class="field === selectedFields[0] ? 'pl-2' : ''" color="primary" dark v-bind="attrs" v-on="on">
                            {{ cellFormatter(sku, field) }}
                          </div>
                        </template>
                        <div>{{ cellFormatter(sku, field) }}</div>
                      </v-tooltip>
                    </div>
                  </span>
                </v-col>
                <v-col v-if="multiRowSelect" class="ma-0 pa-0" style="width: 24px; max-width: 24px">
                  <v-checkbox
                    v-model="sku.SELECTED"
                    :data-test="`checkbox-${index}`"
                    dense
                    hide-details
                    class="ma-0 pa-0"
                    :class="cellClass(sku, 'barcode', 0)"
                    @change="countRows()"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-menu v-model="menuActivated" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y transition="scroll-y-transition" rounded="lg">
      <v-list>
        <v-list-item v-for="(item, index) in tablePopupMenuItems" :key="index" :data-test="`popup-menu-${item.dataTest}`" @click="item.callback && item.callback(item.payload)">
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span :class="item.class">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <swEditSku
      :show-edit-sku="showEditSku"
      :fields="selectedFields"
      :updated-skus="updatedSkus"
      :masterdata="masterdata"
      :sku="skuToEdit"
      :mode="dialogMode"
      :readonly="readonly"
      :is-generating-barcodes="isGeneratingBarcodes"
      @edit-sku-action="applyEditSkuoperation"
    />

    <swChooseNewSkuMethod :show-choose-new-sku-method="showChooseNewSkuMethod" :sku-to-copy="skuToCopy" @close-new-sku-method="showChooseNewSkuMethod = false" />

    <swSkuImportHistory v-model="skuImportHistroyDialog" :files="skuImportFiles" :brand="$route.query.brand" @operation="doneViewSkuImportHisotry" />
    <swYesNoDialog v-model="showYesNoDialog" :message="dialogMessage" style="z-index: 250" @yes="addFileToArchive" @no="handleDialogNo" />
    <swYesNoDialog
      v-model="showCloudsyncDialog"
      :message="swT('cloud_sync_scope')"
      style="z-index: 250;"
      yes-key="newer"
      no-key="all"
      @yes="cloudSync('newer')"
      @no="cloudSync('all')"
    />
    <swXLSImportDialog v-model="showXLSImportDialog" style="z-index: 250;" @operation="setXLSImportOperation" />

    <swPrintDialog v-model="printDialog" :print-buffer="printBuffer" @close-print-dialog="printDialog = false" @ok="printDialog = false" />
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import draggable from 'vuedraggable'
import fieldFunctions from '../functions/skuEditorFunctions'
import globalStore from '../store/globalStore'
import print from '../functions/print'
import productFunctions from '../functions/products'
import tools from '../functions/tools'
import userFunctions from '../functions/users'
import webServices from '../functions/webServicesFacade'
import worker from '../workers/worker-api'

import { choices, updateItems } from '@/functions/skuEditorFieldChoices'
import { deepCopy, hashBrand } from '@softwear/latestcollectioncore'
import { eventBus } from '../main'
import { swT } from '@/functions/i18n'
import { v4 as uuidv4 } from 'uuid'

import swChooseNewSkuMethod from '../components/swChooseNewSkuMethod.vue'
import swCurrency from '../components/swCurrency.vue'
import swEditSku from '../components/swEditSku.vue'
import swPrintDialog from '../components/swPrintDialog.vue'
import swSkuImportHistory from '../components/swSkuImportHistory.vue'
import swSpanCurrency from '../components/swSpanCurrency.vue'
import swXLSImportDialog from '../components/swXLSImportDialog.vue'
import { format } from 'date-fns'
import swYesNoDialog from '../components/swYesNoDialog.vue'

const deHttpUrl = function(url) {
  return url.replace('http://', '').replace('https://', '')
}
function stringToFloat(s) {
  return parseFloat(s.replace(',', '.'))
}
export default {
  components: {
    draggable,
    swChooseNewSkuMethod,
    swCurrency,
    swEditSku,
    swPrintDialog,
    swSkuImportHistory,
    swSpanCurrency,
    swXLSImportDialog,
    swYesNoDialog,
  },
  props: ['skus', 'readonly', 'advancedCommands', 'masterdata', 'ensureFields'],
  data() {
    return {
      swT,
      XLSImportOperation: '',
      activeSort: null,
      addfieldLevelFindDebounced: {},
      cloudSyncBrand: '',
      cloudSyncDataProvider: '',
      cloudSyncing: false,
      contextMenuX: 0,
      contextMenuY: 0,
      dialogMessage: '',
      dialogMode: '',
      editField: true,
      showEditSku: false,
      fieldChoices: choices,
      fieldLevelFind: {},
      fieldLevelFindDebounced: {},
      fieldLevelMinPercentReplace: {},
      fieldLevelMinValueReplace: {},
      fieldLevelPlusPercentReplace: {},
      fieldLevelPlusValueReplace: {},
      fieldLevelReplace: {},
      fields: {},
      fieldsDisabled: false,
      filterSelected: false,
      isGeneratingBarcodes: null,
      loadingStatus: false,
      menuActivated: false,
      multiRowSelect: true,
      newSkuCount: 0,
      page: 1,
      printBuffer: [],
      printDialog: false,
      salePriceRoundMethod: 'no-rounding',
      selectAll: false,
      selectedField: 'barcode',
      selectedFields: [],
      showChooseNewSkuMethod: false,
      showCloudsyncDialog: false,
      showXLSImportDialog: false,
      showYesNoDialog: false,
      skuImportFiles: [],
      skuImportHistroyDialog: false,
      skuToCopy: {},
      skuToEdit: {},
      skusDiffs: {}, // {'32168546512': ['detected sku diff column']}
      slideGroupModel: null,
      state: 0,
      tablePopupMenuItems: [],
      totalDisplayedRows: 0,
      totalRowsOnPage: 50,
      updatedSkus: {},
    }
  },
  computed: {
    isManualSelectionActive() {
      return this.filteredSkus.some((sku) => sku.SELECTED).length > 0
    },
    isAdmin() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw,products-dataprovider_admin')) return true
      return false
    },
    sourceCollection() {
      return !(this.$route.query.masterdata == 'true') && this.isAdmin ? 'selectedbrand' : 'sku'
    },
    translateEnumItems() {
      if (!['enum', 'multiselect'].includes(this.fields[this.selectedField].type)) return []
      return this.fields[this.selectedField].items.map((item) => ({
        ...item,
        text: this.swT(`${item.text}`),
      }))
    },
    uiFieldChoices() {
      if (this.masterdata) return this.fieldChoices.filter((choice) => choice.masterdata).map((field) => ({ text: swT(field.text), value: field.value }))
      return this.fieldChoices.map((field) => ({ text: swT(field.value), value: field.value }))
    },
    uiSelectedFields: {
      get: function() {
        return this.selectedFields.map((field) => ({ text: swT(this.fields[field].text), value: this.fields[field].value }))
      },
      set: function(newVal) {
        // refuse input outside available choices
        if (this.fieldChoices.find((f) => f.value === newVal[newVal.length - 1].value) === undefined) newVal.splice(-1, 1)

        // map between field and value
        this.selectedFields = newVal.map((selectedField) => this.fieldChoices.find((f) => f.value === selectedField.value).value)
        return newVal
      },
    },
    filteredSkus: {
      get: function() {
        this.newSkuCount // triggers computed property
        let filteredResult = this.skus
        if (this.filterSelected) filteredResult = filteredResult.filter((sku) => sku.SELECTED)
        Object.keys(this.fieldLevelFind).forEach((field) => {
          const fieldName = this.fields[field].field
          const fieldType = this.fields[field].type
          const formatter = this.fields[field].formatter
          if (!this.fieldLevelFind[field]) return
          try {
            const regex = new RegExp(this.fieldLevelFind[field], 'i')
            let searchString = this.fieldLevelFind[field].replaceAll(',', '.')
            const exactMatch = !searchString.includes('-')
            const exactValue = parseFloat(searchString)
            if (searchString.substring(0, 1) == '-') searchString = '0' + searchString
            if (searchString.substring(searchString.length - 1) == '-') searchString = searchString + '9999999999'
            const bounds = searchString.split('-')
            bounds[0] = parseFloat(bounds[0])
            bounds[1] = parseFloat(bounds[1])
            filteredResult = filteredResult.filter((sku) => {
              const fieldValue = formatter ? formatter(sku[fieldName]) : sku[fieldName]
              switch (fieldType) {
                case 'boolean':
                  return regex.test(fieldValue ? 'true1' : 'false0')
                case 'currency':
                  if (exactMatch) return fieldValue == exactValue
                  return fieldValue >= bounds[0] && fieldValue <= bounds[1]
                default:
                  return regex.test(fieldValue || '')
              }
            })
          } catch {
            // Most probably an incomplete regular expression, ignore
          }
        })
        this.initReadonlyValues(filteredResult)
        return filteredResult
      },
      set: function(newSkus) {
        return newSkus
      },
    },
  },
  watch: {
    filteredSkus(newValue) {
      this.setPaginationTotalPages(newValue.length)
    },
  },
  async created() {
    if (this.$route.query.newSku && this.$route.query.method) {
      this.clearFieldLevelFinds()
      this.triggerAddSkuMethod({ method: this.$route.query.method })
    }
    if (this.$route.query.newSku && !this.$route.query.method) {
      this.showChooseNewSkuMethod = true
    }
    eventBus.$on('openChooseNewSkuMethod', () => {
      this.showChooseNewSkuMethod = true
    })
    eventBus.$on('closeChooseNewSkuMethod', () => {
      this.showChooseNewSkuMethod = false
    })
    this.initFields()
    await this.$store.state.skuPromise
    this.initSelectedFields()
    updateItems()
    const debounceFn = tools.debounce((newVal) => {
      this.fieldLevelFind = deepCopy(newVal)
    }, 500)
    this.$watch('$store.state.paginationCurrentPage', () => {
      this.page = this.$store.state.paginationCurrentPage
    })
    this.$watch('fieldLevelFindDebounced', debounceFn, { deep: true })
    this.$watch('ensureFields', this.initSelectedFields, { deep: true })
    this.$watch(
      'selectedFields',
      () => {
        if (!this.ensureFields?.length > 0) this.$store.dispatch('updatePreference', { key: 'favorites.skuEditorColumns', value: deepCopy(this.selectedFields) })
      },
      { deep: true }
    )
    this.$watch(
      'totalDisplayedRows',
      () => {
        this.setPaginationTotalPages(this.totalDisplayedRows)
      },
      { deep: true }
    )
    this.$watch(
      'totalRowsOnPage',
      () => {
        const totalDisplayedRows = this.totalDisplayedRows ? this.totalDisplayedRows : this.filteredSkus.length
        this.setPaginationTotalPages(totalDisplayedRows)
      },
      { deep: true }
    )
  },
  async activated() {
    this.eventListeners('$on')
    this.clearFieldLevelFinds()
    eventBus.$on('addSkuMethod', (payload) => {
      this.triggerAddSkuMethod(payload)
    })
    eventBus.$on('openSkuImportHistory', () => {
      this.getSkuImportHistory()
      this.skuImportHistroyDialog = true
    })
    this.initSelectedFields()
    await this.$store.state.skuPromise
    eventBus.$on('importXLSX', () => {
      this.showXLSImportDialog = true
    })
    eventBus.$on('exportXLSX', () => {
      this.downloadXLS()
    })
    eventBus.$on('resetSwSkuEditor', () => {
      this.clearFieldLevelFinds()
    })
    eventBus.$on('updateFieldLevelFind', ({ key, value }) => {
      this.updateFieldLevelFind(key, value)
    })
    eventBus.$on('newSkuCount', () => {
      this.newSkuCount++
    })
    eventBus.$on('exportFashionUnited', () => {
      if (!this.$store.state.user.tenant?.gln) {
        this.$store.dispatch('raiseAlert', {
          header: 'configure_gln_in_tenant',
          type: 'error',
          timeout: 3000,
        })
        return
      }
      const reference = prompt(swT('enter_pricat_reference'))
      if (!reference) return
      const json = productFunctions.skusToPricat(
        this.filteredSkus,
        '8712423008403',
        this.$store.state.user.tenant.gln,
        '1234567890104',
        parseInt(reference),
        format(new Date(), 'yyyyMMdd')
      )
      const csvPricat = productFunctions.pricatToCsv(json)
      tools.saveStringAsFile('latestCollection.pri', csvPricat, 'text/csv')
    })
    eventBus.$on('exportJSON', () => {
      tools.saveStringAsFile('latestCollection.json', JSON.stringify(this.filteredSkus, null, 2), 'text/json')
    })
    eventBus.$on('bindImages', (strategy) => {
      this.bindImages(strategy)
    })
    // eslint-disable-next-line
    eventBus.$on('cloudSync', async ({ hashedBrand, dataProvider }) => {
      this.showCloudsyncDialog = true
      this.cloudSyncBrand = hashedBrand
      this.cloudSyncDataProvider = dataProvider
    })
    eventBus.$on('fpSync', async () => {
      const fpSkus = (await webServices.getFpSkus(this.$store.getters.uploadDirectory)).data
      const answer = window.confirm(swT('import_unknown_skus_from_foxpro'))
      if (answer) {
        // Import unknown barcodes
        const existingSkus = globalStore.getLatestCollectionObject('sku')
        fpSkus.forEach((fpSku) => {
          if (existingSkus[fpSku.id]) return
          this.updatedSkus[fpSku.id] = fpSku
          fpSku.SELECTED = true
          this.skus.push(fpSku)
        })
        this.filterSelected = true
      } else {
        // Sync  at column level
        const answer = window.confirm(swT('sure_to_update_n_columns', this.selectedFields.length))
        if (answer == false) return
        this.fieldsDisabled = true
        this.loadingStatus = true
        const fpSkusIndex = fpSkus.reduce((agg, sku) => {
          agg[sku.id] = sku
          return agg
        }, {})
        this.filteredSkus.forEach((sku) => {
          const fpSku = fpSkusIndex[sku.id]
          if (fpSku) {
            this.selectedFields.forEach((fieldName) => {
              if (fieldName == 'id' || fieldName == 'barcode') return
              const field = this.fields[fieldName].field
              if (sku[field] != fpSku[field] && fpSku[field] != undefined) {
                sku[field] = fpSku[field]
                this.updatedSkus[sku.id] = sku
              }
            })
          }
        })
      }
      this.loadingStatus = false
      this.$store.dispatch('setEditing', true)
      this.validate()
      this.$forceUpdate()
    })
    eventBus.$on('switchToCloudSync', () => {
      this.cloudSyncing = true
      this.skusDiffs = {}
    })
    this.initFields()
  },
  deactivated() {
    this.eventListeners('$off')
    eventBus.$off('importXLSX')
    eventBus.$off('exportXLSX')
    eventBus.$off('exportFashionUnited')
    eventBus.$off('exportJSON')
    eventBus.$off('cloudSync')
    eventBus.$off('addSku')
    eventBus.$off('openSkuImportHistory')
    eventBus.$off('resetSwSkuEditor')
    eventBus.$off('bindImages')
    eventBus.$off('fpSync')
    eventBus.$off('closeShowChooseNewSkuMethod')
    this.$store.state.paginationTotalPages = -1
  },
  methods: {
    triggerAddSkuMethod(payload) {
      if (payload.skuToCopy) {
        // Determine which editsku dialog type to open
        this.showChooseNewSkuMethod = true

        delete payload.skuToCopy.initialBarcode
        this.skuToEdit = deepCopy(payload.skuToCopy)
      }

      if (!payload.skuToCopy) this.skuToEdit = productFunctions.newSku()

      if (payload.method) {
        if (payload.method == 'generate') this.isGeneratingBarcodes = true
        if (payload.method == 'scan') this.isGeneratingBarcodes = false
      }

      if (this.$route.query.brand) this.skuToEdit.brand = this.$route.query.brand
      if (this.$route.query.collection) this.skuToEdit.collection = this.$route.query.collection

      this.dialogMode = 'add-' + (this.masterdata ? 'masterdata' : 'sku')

      this.showEditSku = true
    },
    filterManualSelection() {
      this.filterSelected = !this.filterSelected
    },
    setXLSImportOperation(operation) {
      this.XLSImportOperation = operation
      this.showXLSImportDialog = false
      if (this.XLSImportOperation == 'cancel') return
      this.$refs.input_xlsx.click()
    },
    async cloudSync(scope) {
      const answer = window.confirm(swT('sure_to_update_n_columns', this.selectedFields.length))
      if (answer == false) return
      this.fieldsDisabled = true
      this.showCloudsyncDialog = false
      const hashedBrand = this.cloudSyncBrand
      const dataProvider = this.cloudSyncDataProvider
      this.loadingStatus = true
      await this.$store.dispatch('loadBrand', { brand: hashedBrand, dataProvider })
      const brandSkus = globalStore.getLatestCollectionObject('selectedbrand')
      this.filteredSkus.forEach((sku) => {
        const brandSku = brandSkus[sku.id]
        if (brandSku) {
          if (scope == 'newer' && (brandSku.__modified || brandSku.__created) <= (sku.__modified || sku.__created)) return
          this.selectedFields.forEach((fieldName) => {
            if (fieldName == 'id' || fieldName == 'barcode') return
            const field = this.fields[fieldName].field
            if (sku[field] != brandSku[field] && brandSku[field] != undefined) {
              sku[field] = brandSku[field]
              this.updatedSkus[sku.id] = sku
            }
          })
        }
      })
      this.loadingStatus = false
      this.$store.dispatch('setEditing', true)
      this.validate()
      this.$forceUpdate()
    },
    async bindImages(bindStrategy) {
      const STRATEGIES = {
        articleCode_colorCode: (fName, sku) => fName.includes(sku.articleCodeSupplier?.toLowerCase() + '_' + sku.colorCodeSupplier?.toLowerCase()),
        articleCode_and_colorCode: (fName, sku) => fName.includes(sku.articleCodeSupplier?.toLowerCase() + '_') && fName.includes('_' + sku.colorCodeSupplier?.toLowerCase()),
        articleCode: (fName, sku) => fName.includes(sku.articleCodeSupplier?.toLowerCase()),
        barcode: (fName, sku) => fName == sku.id,
      }
      if (!this.$route.query.brand) return
      this.loadingStatus = true
      const files = (await webServices.getImagesFromCdn(this.$route.query.brand)).data
      if (files.length == 0) return
      const CDN_ROOT = `www.softwear.nl/images/cdn/${this.$route.query.brand}`
      files.forEach((fileName) => {
        const lowerCaseFileName = fileName.toLowerCase()
        const match = lowerCaseFileName.match(/^(.*?)\.[^.]*$/)
        if (!match) return

        const fileNameWithoutExtention = match[1]
        let editing = false
        this.filteredSkus.forEach((sku) => {
          if (sku.images?.includes(fileName)) return
          const fn = STRATEGIES[bindStrategy]
          if (fn(fileNameWithoutExtention, sku)) {
            if (!sku.images) sku.images = `${CDN_ROOT}/${fileName}`
            else sku.images += `,${CDN_ROOT}/${fileName}`
            this.updatedSkus[sku.id] = sku
            sku.SELECTED = true
            if (!editing) {
              this.$store.dispatch('setEditing', true)
              editing = true
            }
          }
        })
      })
      this.loadingStatus = false
      this.validate()
      this.$forceUpdate()
    },
    toggleAll() {
      this.filteredSkus.forEach((sku) => {
        sku.SELECTED = this.selectAll
      })
      this.$forceUpdate()
    },
    selectColumnByName(fieldName) {
      const ind = this.selectedFields.indexOf(fieldName)
      this.cellHeaderClick({ selectedFields: this.selectedFields, index: ind, fields: this.fields })
    },
    calculateMarginField(sku) {
      sku['margin'] = productFunctions.calculateMargin(sku, this.$store.state.vatHi)
    },
    calculateCalculationField(sku) {
      if (sku.buyPrice || sku.price || sku.price == 0) {
        sku['calculation'] = 0.0
      }
      sku['calculation'] = (sku.price / sku.buyPrice).toFixed(1)
    },
    handleYesNoDialog(message) {
      this.dialogMessage = swT(message)
      this.showYesNoDialog = true
    },
    handleDialogNo() {
      this.showYesNoDialog = false
    },
    cb(e, field) {
      this.fieldLevelReplace[field] = e.srcElement._value
    },
    async getSkuImportHistory() {
      try {
        const data = await webServices.getSkuImportHistory(hashBrand(this.$route.query.brand))
        if (data.data?.length > 0) {
          this.skuImportFiles = data.data
        }
      } catch (error) {
        this.skuImportFiles = []
      }
    },
    skuSource(sku) {
      let color
      const lightThemeColors = this.$vuetify.theme.themes.light
      if (sku.usedByTenant && sku.source == 'latestCollection') color = lightThemeColors.info
      else if (sku.source == 'latestCollection') color = colors.green.base
      else if (sku.usedByTenant) color = lightThemeColors.warning
      else color = 'grey'
      return color
    },
    clearFieldLevelFinds() {
      this.addfieldLevelFindDebounced = {}
      this.newSkuCount = 0
      Object.keys(this.fieldLevelFindDebounced).forEach((key) => (this.fieldLevelFindDebounced[key] = ''))
    },
    selectSku(sku) {
      this.skuToEdit = sku

      if (this.$route.path === '/products') this.dialogMode = 'display-data'
      else this.dialogMode = 'edit-' + (this.masterdata ? 'masterdata' : 'sku')

      this.showEditSku = true
    },
    doneViewSkuImportHisotry(payload) {
      if (payload.done) {
        this.skuImportHistroyDialog = false
      }
    },
    applyEditSkuoperation(payload) {
      if (payload.done) {
        if (!this.$store.state.editing) this.updateFieldLevelFind('articlecode', '')
        this.showEditSku = false
        this.validate()
        return
      }
      if (payload.addSku) {
        const sku = payload.addSku
        sku.size.forEach((size) => {
          const newSku = deepCopy(sku)
          newSku.size = size
          newSku.mainSize = size
          newSku.sizeSupplier = size
          newSku.id = payload.isGeneratingBarcodes ? 'tmp-' + uuidv4() : newSku.barcode
          newSku.barcode = newSku.id
          this.updatedSkus[newSku.id] = newSku
          this.$emit('addSku', newSku)
        })
        this.$store.dispatch('setEditing', true)

        return
      }
      if (payload.updateSku) {
        this.showEditSku = false
        const sku = payload.updateSku
        this.updatedSkus[sku.id] = sku
        this.$emit('updateSku', sku)
        this.$store.dispatch('setEditing', true)
      }
    },
    initSelectedFields() {
      if (this.masterdata) {
        this.selectedFields = this.uiFieldChoices.map((choice) => choice.value)
        return
      }
      const preferedFields = this.$store.state.activeConfig.favorites.skuEditorColumns.value
      if (JSON.stringify(this.selectedFields) != JSON.stringify(preferedFields) || this.ensureFields?.length > 0) {
        if (!preferedFields.includes('barcode')) this.selectedFields = ['barcode', ...preferedFields]
        else this.selectedFields = [...preferedFields]
        if (this.ensureFields?.length > 0) {
          this.ensureFields
            .map((f) => (f.substring(0, 1) == '_' ? f.substring(1) : f))
            .forEach((f) => {
              if (!this.selectedFields.includes(f)) this.selectedFields.push(f)
            })
        }
        this.selectedFields = this.selectedFields.filter((field) => this.fields[field])
        this.selectedField = 'barcode'
      }
    },
    canBeRemoved(value) {
      return !(this.fieldLevelFind[value] || value === 'barcode')
    },
    removeSelectedField(field) {
      this.selectedFields.splice(this.selectedFields.indexOf(field.value), 1)
      delete this.fieldLevelFind[field.value]
      this.initColumnSelection()
    },
    initColumnSelection() {
      this.cellHeaderClick({ selectedFields: this.selectedFields, index: 0, fields: this.fields })
    },
    cellHeaderClick(params) {
      const { index } = params
      const { fields, activeSort, sortedSkus } = tools.skuOneColumnSorting(params, this.selectedField, this.activeSort, this.filteredSkus)
      this.fields = fields
      this.activeSort = activeSort
      this.selectedField = this.selectedFields[index]
      this.filteredSkus = sortedSkus
      this.$forceUpdate()
    },
    rowClass(index, sku) {
      let rowClass = 'row-border'
      if (sku.SELECTED) rowClass += ' green lighten-5'
      else if (index % 2 === 1) rowClass += ' grey lighten-4'
      if (sku.active === false) rowClass += ' text-decoration-line-through'
      return rowClass
    },
    cellClass(sku, fieldName, rowIndex) {
      let cellClass = 'cell-border '
      const { required, recommended, field } = this.fields[fieldName]
      if (!this.masterdata && !this.readonly && !sku[field]) {
        if (required) return rowIndex % 2 === 1 ? (cellClass += 'red lighten-4') : (cellClass += 'red lighten-5')
        if (recommended) return rowIndex % 2 === 1 ? (cellClass += 'orange lighten-4') : (cellClass += 'orange lighten-5')
      }
      if (this.fields[fieldName].type == 'currency') cellClass += ' text-right'
      return cellClass
    },
    columnHeaderClass(field) {
      return this.fields[field].type == 'currency' ? 'text-right' : 'text-left'
    },
    initFields() {
      this.fieldsDisabled = false
      this.fieldChoices.forEach((choice) => (choice.text = swT(choice.text)))
      this.preferedAttributes().forEach((field) => {
        if (!this.fieldChoices.find((fc) => fc.field == field.field)) this.fieldChoices.push(field)
      })
      this.fields = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: item,
        }
      }, {})
      this.fieldLevelFind = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
      this.fieldLevelReplace = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
      this.fieldLevelMinPercentReplace = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
      this.fieldLevelPlusPercentReplace = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
      this.fieldLevelPlusValue = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
      this.fieldLevelMinValue = this.fieldChoices.reduce((obj, item) => {
        return {
          ...obj,
          [item.value]: '',
        }
      }, {})
    },
    initReadonlyValues(skus) {
      if (skus.length > 0) {
        skus.forEach((sku) => {
          if (sku?.campaigns != '' && sku?.campaigns != undefined) {
            this.calculateCampaignSalesPrice(sku, sku.campaigns)
          }

          if (sku?.buyPrice != '' && sku?.buyPrice != undefined && sku?.price != '' && sku?.price != undefined) {
            this.calculateMarginField(sku)
            this.calculateCalculationField(sku)
          }
        })
      }
    },
    calculateCampaignSalesPrice(sku, selectedCampaigns) {
      let campaignSalesPrice = 0
      const campaigns = globalStore.getLatestCollectionObject('campaign')
      const selectedCampaignsArray = selectedCampaigns.split(',')

      for (const campaign of selectedCampaignsArray) {
        if (campaigns.length > 0 && campaigns[campaign]?.active) {
          if (campaigns[campaign]?.discountRule == 'percentage') {
            campaignSalesPrice = sku.price - (sku.price / 100) * campaigns[campaign].parameter
          }
          if (campaigns[campaign]?.discountRule == 'amount') {
            campaignSalesPrice = sku.price - campaigns[campaign].parameter
          }
          if (campaigns[campaign]?.discountRule == 'replace') {
            campaignSalesPrice = campaigns[campaign].parameter
          }

          sku['CampaignSalesPrice'] = campaignSalesPrice
        }
      }
    },
    eventListeners(mode) {
      eventBus[mode]('save', this.save)
      eventBus[mode]('cancel', this.cancel)
    },
    originalSkuFormatter(sku, fieldName) {
      const id = sku.id
      const originalSku = globalStore.getLatestCollectionObject('sku')[id]
      if (!originalSku) return ''
      const field = this.fields[fieldName].field
      if (originalSku[field] == undefined) return ''
      return originalSku[field]
    },
    skuSyncDiff(sku, index) {
      const lastRow = index === this.filteredSkus.slice(0, this.totalRowsOnPage).length - 1

      // If not in cloud syncing mode, display the row and count rows total by the last iteration
      if (!this.cloudSyncing) {
        if (lastRow) this.countRows()
        return true
      }

      // Loop through all selected fields and check if these fields have a different value
      for (const fieldName of this.selectedFields) {
        if (['id', 'barcode'].includes(fieldName)) continue
        const id = sku.id
        const originalSku = globalStore.getLatestCollectionObject('sku')[id]
        if (!originalSku) continue
        const field = this.fields[fieldName].field
        if (!field) continue

        // If there is a different value
        if (originalSku[field] !== sku[field]) {
          // If the barcode has been not set yet into skuDiffs, add it and assign an empty list to it
          if (!this.skusDiffs[sku.id]) this.skusDiffs[sku.id] = []
          // Add the field name to the list that has been assigned to the barcode in skuDiffs barcode
          this.skusDiffs[id].push(fieldName)
        }
      }

      // If this is the last row, count displayed rows
      if (lastRow) this.countRows()

      // Return if the row may/not be displayed
      return this.skusDiffs[sku.id]?.length
    },
    countRows() {
      // I don't know why this still triggers when the vuew is already inactive but the code should not run because
      // this.$store.state.paginationTotalPages is set to -1 in the deactivated hook and it should stay that way.
      if (this._inactive == true) return

      if (this.nrSelectedSkus()) {
        this.totalDisplayedRows = this.nrSelectedSkus()
        return
      }

      // If it's not in syncing display the total of filteredSkus
      if (!this.cloudSyncing) this.totalDisplayedRows = this.filteredSkus.length
      // If there is syncing and no filtering applied, display the total of skusDiffs
      else if (this.filteredSkus.length === this.skus.length) this.totalDisplayedRows = Object.keys(this.skusDiffs).length
      // If there is syncing and filtering applied, display the total of the filtered skus with differences
      else this.totalDisplayedRows = this.filteredSkus.filter((sku) => this.skusDiffs[sku.id]).length
    },
    skuReplaceDiff(sku, fieldName) {
      // If there are no updatedSkus or the updatedSkus object doesn't contain the passed sku stop execution
      if (!Object.keys(this.updatedSkus).length || !this.updatedSkus[sku.id]) return false
      if (['id', 'barcode'].includes(fieldName)) return false
      const id = sku.id
      const originalSku = globalStore.getLatestCollectionObject('sku')[id]
      if (!originalSku) return false
      const field = this.fields[fieldName].field
      if (!field) return false
      return originalSku[field] !== sku[field]
    },
    displayDifferences(sku, fieldName) {
      // Display differences only if there are differences in syncing, or in replacing values
      return (this.cloudSyncing && this.skusDiffs[sku['id']] && this.skusDiffs[sku['id']].includes(fieldName)) || this.skuReplaceDiff(sku, fieldName)
    },
    cellFormatter(sku, fieldName) {
      const fieldValue = sku[this.fields[fieldName].field] || ''
      const formatter = this.fields[fieldName].formatter
      if (formatter) return formatter(fieldValue)
      return fieldValue
    },
    async downloadXLS() {
      this.$store.state.exportBusy = true
      const filteredSkus = this.filteredSkus
      const url = await worker.xlsxWrite(filteredSkus)
      await webServices.forceDownload(url, 'latestCollectionSkus.xlsx')
      this.$store.state.exportBusy = false
    },
    async uploadXLS(e) {
      const XLSImportOperation = this.XLSImportOperation
      const file = e.target.files[0]
      const reader = new FileReader()
      const skus = this.skus
      const fieldChoices = this.fieldChoices
      const selectedFields = this.selectedFields
      const fields = this.fields
      const brandId = this.$route.query.brand
      const updatedSkus = this.updatedSkus
      const $store = this.$store
      $store.state.importBusy = true
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = await worker.xlsxRead(data)
        $store.state.importBusy = false

        const sheet = workbook.Sheets[Object.keys(workbook?.Sheets)[0]]
        if (!sheet || !sheet['!ref'])
          return this.$store.dispatch('raiseAlert', {
            header: 'notavalidspreadsheet',
            type: 'error',
            timeout: 5000,
          })
        const range = sheet['!ref'].split(':')
        const re = /(?<col>[A-Z]*)(?<row>[0-9]*)/
        const upperLeftCoordinate = range[0].match(re).groups
        const topRow = upperLeftCoordinate.row
        const sheetKeys = Object.keys(sheet)
        const rows = {}
        sheetKeys
          .filter((key) => key[0] != '!')
          .forEach((key) => {
            const coordinates = key.match(re).groups
            const col = coordinates.col
            const row = coordinates.row
            if (!rows[row]) {
              if (XLSImportOperation == 'update_only') rows[row] = {}
              else rows[row] = { brand: brandId, source: 'import' }
            }
            if (row != topRow) rows[row][rows[topRow][col]] = sheet[key].v + ''
            else rows[row][col] = sheet[key].v + ''
          })
        if (!rows[2])
          return this.$store.dispatch('raiseAlert', {
            header: 'notavalidspreadsheet',
            type: 'error',
            timeout: 5000,
          })
        const columns = deepCopy(rows[2])
        delete rows[topRow] // Header row
        const skuIndex = skus.reduce((agg, sku, index) => {
          agg[sku.id] = index
          return agg
        }, {})
        const excludeFields = ['source', 'price', 'color', 'id', 'colorCode', 'suggestedRetailPrice', '__created', '__modified']
        const imageFields = []
        for (let i = 0; i < 9; i++) {
          const shortKey = `img${i}`
          const longKey = `image${i}`
          imageFields.push(shortKey)
          imageFields.push(longKey)
        }
        const importErrors = []
        Object.keys(rows).forEach((key) => {
          const row = rows[key]
          if (typeof row.price == 'string') row.price = stringToFloat(row.price)
          if (typeof row.buyPrice == 'string') row.buyPrice = stringToFloat(row.buyPrice)
          if (typeof row.salePrice == 'string') row.salePrice = stringToFloat(row.salePrice)
          if (typeof row.valuePrice == 'string') row.valuePrice = stringToFloat(row.valuePrice)

          if (row.color) row.colorFamily = row.color
          if (row.color && !row.colorSupplier) row.colorSupplier = row.color
          if (row.colorCode && !row.colorCodeSupplier) row.colorCodeSupplier = row.colorCode
          if (row.articleCode && !row.articleCodeSupplier) row.articleCodeSupplier = row.articleCode
          if (row.articleGroup && !row.articleGroupSupplier) row.articleGroupSupplier = row.articleGroup
          if (row.collection && !row.collectionSupplier) row.collectionSupplier = row.collection
          if (row.size && !row.sizeSupplier) row.sizeSupplier = row.size
          const images = []
          for (const key of imageFields) if (row[key]) images.push(deHttpUrl(row[key]))
          if (images.length > 0) row.images = images.join(',')

          if (row.ean) row.barcode = row.ean
          if (!row.barcode) row.barcode = row.id
          if (!row.barcode) {
            if (!importErrors.includes('id_or_ean_field_missing')) importErrors.push('id_or_ean_field_missing')

            return
          }
          row.id = row.barcode
          const existingSku = skus[skuIndex[row.id]]
          if (existingSku && (XLSImportOperation == 'update_only' || XLSImportOperation == 'all')) {
            // Check if there is any change
            if (updatedSkus[row.barcode] || Object.keys(row).find((k) => row[k] != existingSku[k])) {
              if (XLSImportOperation == 'update_only') existingSku.SELECTED = true
              Object.assign(existingSku, row)
              if (!updatedSkus[row.barcode]) updatedSkus[row.barcode] = existingSku || {}
              updatedSkus[row.barcode] = Object.assign(updatedSkus[row.barcode], row)
            }
          }
          if (!existingSku && (XLSImportOperation == 'all' || XLSImportOperation == 'new_only')) {
            if (XLSImportOperation == 'new_only') row.SELECTED = true
            skus.push(row)
            skuIndex[row.id] = skus.length - 1
            if (!updatedSkus[row.barcode]) updatedSkus[row.barcode] = existingSku || {}
            updatedSkus[row.barcode] = Object.assign(updatedSkus[row.barcode], row)
          }
        })
        if (importErrors.length > 0) {
          return $store.dispatch('raiseAlert', {
            header: 'import_errors',
            body: importErrors.join(','),
            type: 'error',
            timeout: 5000,
          })
        }
        if (XLSImportOperation == 'update_only' || XLSImportOperation == 'new_only') this.filterSelected = true
        // Add fields to fieldChoices and selectedFields
        Object.keys(columns)
          .filter((key) => !excludeFields.includes(key) && !imageFields.includes(key))
          .forEach((key) => {
            const lowerKey = key.toLowerCase()
            if (!fields[lowerKey]) {
              const newField = {
                value: lowerKey,
                field: key,
                text: key,
                type: 'text',
              }
              fieldChoices.push(newField)
              fields[lowerKey] = newField
            }
            if (!selectedFields.includes(lowerKey)) selectedFields.push(lowerKey)
          })
        this.$nextTick(() => {
          this.$store.dispatch('setEditing', true)
          this.validate()
          this.$forceUpdate()
        })
      }
      reader.readAsArrayBuffer(file)
    },
    async addFileToArchive() {
      this.showYesNoDialog = false

      try {
        const blob = await worker.xlsxBlob(this.filteredSkus)
        const response = await webServices.importSkuFileToArchive(hashBrand(this.$route.query.brand), this.filteredSkus[0].collection, blob)
        this.$store.state.loading = false

        if (response.status != 200) {
          this.$store.dispatch('raiseAlert', {
            header: 'upload_to_archive_failed',
            type: 'error',
            timeout: 3000,
          })
        }
      } catch {
        this.$store.state.loading = false

        this.$store.dispatch('raiseAlert', {
          header: 'upload_to_archive_failed',
          type: 'error',
          timeout: 3000,
        })
      }
    },
    preferedAttributes() {
      const groups = this.$store.state.activeConfig.products.activeAttributes.value || []
      return groups.map((group) => {
        return {
          value: group,
          field: '_' + group,
          text: group,
          type: 'combobox',
          masterdata: true,
          items: fieldFunctions.getItems('_' + group),
        }
      })
    },
    validate() {
      this.$store.state.activeFormValid = true
    },
    replaceField(fieldName) {
      const field = this.fields[fieldName].field
      let replaceValue = this.fieldLevelReplace[fieldName]
      if (this.fields[fieldName].type == 'boolean') replaceValue = replaceValue == 'true' || replaceValue == '1' ? true : false
      if (this.fields[fieldName].type == 'currency') replaceValue = stringToFloat(replaceValue)
      if (this.fields[fieldName].type == 'multiselect') replaceValue = replaceValue ? replaceValue.join(',') : ''
      if (typeof replaceValue == 'object' && field != 'campaigns') replaceValue = replaceValue.text

      const nrSelectedSkus = this.nrSelectedSkus()

      this.filteredSkus.forEach((sku) => {
        if (nrSelectedSkus && !sku.SELECTED) return

        if (this.fields[fieldName].type == 'currency' && this.fieldLevelMinPercentReplace[fieldName]) {
          const replacePercentValue = stringToFloat(this.fieldLevelMinPercentReplace[fieldName])
          if (replacePercentValue != 0) {
            if (field == 'salePrice') replaceValue = this.roundSalePrice((sku.price * (100 - replacePercentValue)) / 100)
            else replaceValue = (sku[field] * (100 - replacePercentValue)) / 100
          }
        }
        if (this.fields[fieldName].type == 'currency' && this.fieldLevelPlusPercentReplace[fieldName]) {
          const replacePercentValue = stringToFloat(this.fieldLevelPlusPercentReplace[fieldName])
          if (replacePercentValue != 0) {
            if (field == 'salePrice') replaceValue = this.roundSalePrice((sku.price * (100 + replacePercentValue)) / 100)
            else replaceValue = (sku[field] * (100 + replacePercentValue)) / 100
          }
        }
        if (this.fields[fieldName].type == 'currency' && this.fieldLevelPlusValueReplace[fieldName]) {
          const replacePlusValue = stringToFloat(this.fieldLevelPlusValueReplace[fieldName])
          if (replacePlusValue != 0) {
            if (field == 'salePrice') replaceValue = sku.price + replacePlusValue
            else replaceValue = sku[field] + replacePlusValue
          }
        }
        if (this.fields[fieldName].type == 'currency' && this.fieldLevelMinValueReplace[fieldName]) {
          const replaceMinValue = stringToFloat(this.fieldLevelMinValueReplace[fieldName])
          if (replaceMinValue != 0) {
            if (field == 'salePrice') replaceValue = sku.price - replaceMinValue
            else replaceValue = sku[field] - replaceMinValue
          }
        }

        if (field == 'campaigns') {
          this.calculateCampaignSalesPrice(sku, replaceValue)
        }
        if (field == 'price' || field == 'buyPrice') {
          this.calculateMarginField(sku)
          this.calculateCalculationField(sku)
        }
        sku[field] = replaceValue
        sku.id = sku.barcode
        this.updatedSkus[sku.id] = sku
      })

      this.fieldLevelReplace[field] = ''
      this.$store.dispatch('setEditing', true)
      this.validate()
      this.$forceUpdate()
    },
    clearField(fieldName) {
      const field = this.fields[fieldName].field
      let replaceValue = this.fieldLevelReplace[fieldName]
      if (this.fields[fieldName].type == 'boolean') replaceValue = false
      if (this.fields[fieldName].type == 'currency') replaceValue = stringToFloat('0')
      if (this.fields[fieldName].type == 'multiselect') replaceValue = ''
      this.filteredSkus.forEach((sku) => {
        sku[field] = replaceValue
        sku.id = sku.barcode
        this.updatedSkus[sku.id] = sku
      })
      this.fieldLevelReplace[field] = ''
      this.$store.dispatch('setEditing', true)
      this.validate()
      this.$forceUpdate()
    },
    setHeaderClass(index) {
      let baseClass = 'header ellipsis pa-1 ma-0 caption pointer '
      if (this.selectedField === this.selectedFields[index]) baseClass += 'green'
      else if (this.fields[this.selectedFields[index]]?.readOnly) baseClass += 'info lighten-1'
      else if (!this.fieldLevelFind[this.selectedFields[index]]) baseClass += 'info'
      else baseClass += 'orange accent-2'
      return baseClass
    },
    setFilterClass(index) {
      return this.selectedField === this.selectedFields[index] ? 'green lighten-5' : !this.fieldLevelFind[this.selectedFields[index]] ? 'grey lighten-3' : 'orange accent-2'
    },
    setChipColor(chipValue) {
      return chipValue.value === this.fields[this.selectedField].value ? 'green' : !this.fieldLevelFind[chipValue.value] ? 'info' : 'orange accent-3'
    },
    validateSkus(skus) {
      const mixedErrors = []
      const emptyFields = []
      let errorMessage = ''
      if (tools.getUniqueValues(skus.map((s) => s.brand)).lenght > 1) mixedErrors.push(swT('too_many_brands'))
      const nrUniqueBarcodes = tools.getUniqueValues(skus.map((s) => s.id)).length
      if (nrUniqueBarcodes != skus.length) mixedErrors.push(swT('barcodes_must_be_unique'))
      if (skus.find((s) => s.barcode.substring(0, 4) != 'tmp-' && !s.barcode)) mixedErrors.push(swT('incorrect_barcode'))
      if (skus.find((s) => s.price < 0 || s.buyPrice < 0 || s.sellPrice < 0)) mixedErrors.push(swT('prices_should_be_positive'))
      if (skus.find((s) => !s.articleCode)) emptyFields.push(swT('articlecode'))
      if (skus.find((s) => !s.brand)) emptyFields.push(swT('brand'))

      if (mixedErrors.length) errorMessage = mixedErrors.join('\n')
      if (emptyFields.length) errorMessage += `${mixedErrors.length ? '\n' : ''}${swT('mandatory_fields')}\n${emptyFields.join('\n')}`

      return errorMessage ? errorMessage : null
    },
    removeEarlierAddedSkus() {
      // This function removes all skus that have been earlier saved which
      // have been created using generate barcode to avoid duplicated adding
      this.updatedSkus = Object.keys(this.updatedSkus)
        .filter((key) => !!this.updatedSkus[key].id)
        .reduce((obj, key) => {
          obj[key] = this.updatedSkus[key]
          return obj
        }, {})
    },
    async save() {
      this.removeEarlierAddedSkus()
      if (!this.masterdata) {
        const errors = this.validateSkus(Object.values(this.updatedSkus))
        if (this.isAdmin) {
          this.handleYesNoDialog('upload_to_sku_archive')
        }
        if (errors) {
          alert(errors)
          return
        }
      }
      this.$emit('refresh')
      this.$store.dispatch('setEditing', false)
      this.$emit('save', this.updatedSkus)
      this.initFields()
      this.cloudSyncing = false
    },
    cancel() {
      this.$emit('refresh')
      this.$store.dispatch('setEditing', false)
      this.$emit('cancel')
      this.initFields()
      this.cloudSyncing = false
      this.filterSelected = false
      this.selectAll = false
    },
    skuImages(sku) {
      if (!sku.images) return
      return sku.images.split(',')
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
    deActivateSku(sku) {
      const nrSelectedSkus = this.nrSelectedSkus()
      if (nrSelectedSkus > 1) {
        this.filteredSkus
          .filter((sku) => sku.SELECTED)
          .forEach((sku) => {
            sku.active = false
            this.updatedSkus[sku.id] = sku
          })
      } else {
        sku.active = false
        this.updatedSkus[sku.id] = sku
      }
      this.$store.dispatch('setEditing', true)
      this.validate()
    },
    activateSku(sku) {
      const nrSelectedSkus = this.nrSelectedSkus()
      if (nrSelectedSkus > 1) {
        this.filteredSkus
          .filter((sku) => sku.SELECTED)
          .forEach((sku) => {
            sku.active = true
            this.updatedSkus[sku.id] = sku
          })
      } else {
        sku.active = true
        this.updatedSkus[sku.id] = sku
      }
      this.$store.dispatch('setEditing', true)
      this.validate()
    },
    copySku(sku) {
      const skuToCopy = deepCopy(sku)
      skuToCopy.initialBarcode = skuToCopy.barcode
      delete skuToCopy.id
      delete skuToCopy.barcode
      this.skuToCopy = skuToCopy
      this.showChooseNewSkuMethod = true
    },
    printSkuBarcode(sku) {
      this.printBuffer = []
      this.filteredSkus
        .filter((sku) => sku.SELECTED)
        .forEach((sku) => {
          this.printBuffer.push(print.sku4BarcodeLabel(sku, 1))
        })
      if (this.printBuffer.length == 0) this.printBuffer = [print.sku4BarcodeLabel(sku, 1)]
      this.printDialog = true
    },
    goToRoute(route) {
      this.$router.push(route)
    },
    nrSelectedSkus() {
      return this.filteredSkus.reduce((acc, c) => (c.SELECTED ? ++acc : acc), 0)
    },
    activateMenu(e, sku) {
      e.preventDefault()
      const nrSelectedSkus = this.nrSelectedSkus()
      const multiRowSelectCount = nrSelectedSkus > 1 ? ` (${nrSelectedSkus})` : ''

      this.tablePopupMenuItems = []
      if (this.readonly) {
        this.tablePopupMenuItems.push({
          title: this.swT('open'),
          dataTest: 'open',
          icon: 'mdi-eye',
          callback: this.selectSku,
          class: 'info--text',
          payload: sku,
        })
      } else {
        this.tablePopupMenuItems.push({
          title: this.swT('edit'),
          dataTest: 'edit',
          icon: 'mdi-pencil',
          callback: this.selectSku,
          class: 'info--text',
          payload: sku,
        })
        if (!this.masterdata) {
          this.tablePopupMenuItems.push({
            title: this.swT('copy'),
            dataTest: 'copy',
            icon: 'mdi-content-copy',
            callback: this.copySku,
            class: 'info--text',
            payload: sku,
          })
          this.tablePopupMenuItems.push({
            title: this.swT('print') + multiRowSelectCount,
            dataTest: 'printLabel',
            icon: 'mdi-barcode',
            callback: this.printSkuBarcode,
            class: 'info--text',
            payload: sku,
          })
          if (!this.isAdmin)
            if (sku.active || nrSelectedSkus > 1)
              this.tablePopupMenuItems.push({
                title: this.swT('deactivate') + multiRowSelectCount,
                dataTest: 'deactivate',
                icon: 'mdi-close',
                callback: this.deActivateSku,
                class: 'warning--text',
                payload: sku,
              })
          if (!sku.active || nrSelectedSkus > 1)
            this.tablePopupMenuItems.push({
              title: this.swT('activate') + multiRowSelectCount,
              dataTest: 'activate',
              icon: 'mdi-check',
              callback: this.activateSku,
              class: 'success--text',
              payload: sku,
            })
          if (sku.pricat)
            this.tablePopupMenuItems.push({
              title: this.swT('openPricat'),
              dataTest: 'openPricat',
              icon: 'mdi-matrix',
              callback: this.openPricat,
              class: 'info--text',
              payload: sku,
            })
        }
      }

      if (!this.isAdmin)
        this.tablePopupMenuItems.push({
          title: this.swT('history'),
          dataTest: 'history',
          icon: 'mdi-table-eye',
          callback: this.goToRoute,
          class: 'info--text',
          payload: {
            path: 'reports',
            query: {
              type: 'articlestatus',
              groups: 'sku.articleCode,sku.colorFamily,sku.size,sku.id,transaction.time,transaction.type,transaction.docnr,wh.name',
              filter: `sku.id == "${sku.id}"`,
              fields: '6,8,12,14,41',
              period: 'LASTMONTH',
              reportViz: 'table',
            },
          },
        })
      if (this.showDownloadSkuMenuItem(sku))
        this.tablePopupMenuItems.push({
          title: this.swT('download') + multiRowSelectCount,
          dataTest: 'download',
          icon: 'mdi-cloud-download-outline',
          callback: this.saveProductToTenantDb,
          class: 'info--text',
          payload: sku,
        })
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw,products-admin')) {
        this.tablePopupMenuItems.push({
          title: this.swT('delete') + multiRowSelectCount,
          dataTest: 'delete',
          icon: 'mdi-trash-can',
          callback: this.deleteSku,
          class: 'error--text',
          payload: sku,
        })
      }
      this.tablePopupMenuItems.push({
        title: this.swT('googleSearchSku'),
        dataTest: 'googleSearch',
        icon: 'mdi-google',
        callback: this.googleSearchSku,
        class: 'info--text',
        payload: sku,
      })
      this.tablePopupMenuItems.push({
        title: this.swT('close'),
        dataTest: 'close',
        icon: 'mdi-exit-to-app',
        callback: null,
        class: 'info--text',
        payload: {},
      })
      this.menuActivated = false
      this.contextMenuX = e.clientX
      this.contextMenuY = e.clientY
      this.$nextTick(() => {
        this.menuActivated = true
      })
    },
    updateFieldLevelFind(key, value) {
      if (this.dialogMode !== 'add-sku') return
      if (!this.addfieldLevelFindDebounced[key]) {
        this.addfieldLevelFindDebounced[key] = []
      }
      this.addfieldLevelFindDebounced[key][this.newSkuCount] = value

      // Remove duplicates and undefined or empty items
      const filtersList = [...new Set(this.addfieldLevelFindDebounced[key])].filter((v) => v)

      // Convert list to string with | separator
      const regexCtx = filtersList.join('|')

      this.fieldLevelFindDebounced[key] = regexCtx
      this.fieldLevelFind[key] = regexCtx
    },
    isUISelectedFieldDisabled(item) {
      return item.value === 'barcode'
    },
    googleSearchSku(sku) {
      window.open(`https://www.google.com/search?q=${sku.barcode}`, '_blank')
    },
    openPricat(sku) {
      window.open('https://www.softwear.nl/pricats/' + sku.pricat, '_blank')
    },
    async deleteSku(sku) {
      const answer = window.confirm(this.swT('are_you_sure_you_want_to_delete_this_selection'))
      if (!answer) return
      try {
        const nrSelectedSkus = this.nrSelectedSkus()
        let barcodesToDelete = []
        if (nrSelectedSkus > 1) {
          barcodesToDelete = this.filteredSkus.filter((sku) => sku.SELECTED)
        } else barcodesToDelete.push(sku)
        this.$store.state.loading = true
        await this.$store.dispatch('deleteObjects', {
          api: globalStore.getLatestCollectionAPI(this.sourceCollection),
          data: barcodesToDelete,
          auditHeaders: {
            'x-transaction-audit-source': 'Sku-editor',
          },
        })
        barcodesToDelete.forEach((barcode) => {
          const indexOfBarcodeToDelete = this.skus.findIndex((s) => s.id == barcode.id)
          this.skus.splice(indexOfBarcodeToDelete, 1)
        })
        this.$forceUpdate()
      } finally {
        this.$store.state.loading = false
      }
    },
    async saveProductToTenantDb(sku) {
      try {
        const nrSelectedSkus = this.nrSelectedSkus()
        let barcodesToSave = []
        if (nrSelectedSkus > 1) {
          barcodesToSave = this.filteredSkus.filter((sku) => sku.SELECTED)
        } else barcodesToSave.push(sku)

        this.$store.state.loading = true
        await this.$store.dispatch('updateSkuObjects', { data: barcodesToSave, audit: 'Sku Editor' })

        this.$forceUpdate()
      } finally {
        this.$store.state.loading = false
      }
    },
    showDownloadSkuMenuItem(sku) {
      // Check whether the sku has been created locally or is downloaded before.
      // Which in both cases doesn't have to show the download menu item for the sku.
      const isNotLocallyCreatedSku = !((!sku.usedByTenant && !sku.source) || (sku.usedByTenant && sku.source === 'tenant'))
      const isNotDownloadedSku = !sku.usedByTenant || sku.source !== 'latestCollection'
      return isNotLocallyCreatedSku && isNotDownloadedSku
    },
    showSalePricePopup(e, selectedField) {
      // Show a popup only on saleprice to choose the round method
      if (selectedField !== 'saleprice') return
      e.preventDefault()

      this.tablePopupMenuItems = []
      this.tablePopupMenuItems.push({
        title: this.swT('no-rounding'),
        dataTest: 'no-rounding',
        icon: 'mdi-equal',
        callback: this.setSalePriceRoundMethod,
        class: this.salePriceRoundMethod === 'no-rounding' ? 'success--text' : 'info--text',
        payload: { event: e, roundMethod: 'no-rounding' },
      })
      this.tablePopupMenuItems.push({
        title: this.swT('half-rounding'),
        dataTest: 'half-rounding',
        icon: 'mdi-approximately-equal',
        callback: this.setSalePriceRoundMethod,
        class: this.salePriceRoundMethod === 'half-rounding' ? 'success--text' : 'info--text',
        payload: { event: e, roundMethod: 'half-rounding' },
      })
      this.tablePopupMenuItems.push({
        title: this.swT('whole-rounding'),
        dataTest: 'whole-rounding',
        icon: 'mdi-approximately-equal-box',
        callback: this.setSalePriceRoundMethod,
        class: this.salePriceRoundMethod === 'whole-rounding' ? 'success--text' : 'info--text',
        payload: { event: e, roundMethod: 'whole-rounding' },
      })
      this.menuActivated = false
      this.contextMenuX = e.clientX
      this.contextMenuY = e.clientY
      this.$nextTick(() => {
        this.menuActivated = true
      })
    },
    setSalePriceRoundMethod(payload) {
      this.salePriceRoundMethod = payload.roundMethod
      //Focus the input field after hiding the popup menu
      payload.event.target.focus()
    },
    roundSalePrice(salePrice) {
      // If half-rounding
      if (this.salePriceRoundMethod === 'half-rounding') {
        const intPart = Math.trunc(salePrice)
        const decimalPortionString = String(salePrice).split('.')[1]
        // Reformat the decimal portion as follow to be compared on next step:
        // 0.5 we get 5 it will be 50
        // 0.45355563 we get 45355563 it will be 45
        // 0.45 we get 0.45 it being used as-is without changing
        const decimalPortionNumber = Number(
          decimalPortionString.length < 2 ? decimalPortionString + '0' : decimalPortionString.length > 2 ? decimalPortionString.substring(0, 2) : decimalPortionString
        )

        // 3.24 => 3.00
        if (decimalPortionNumber < 25) return Number(intPart.toFixed(2))
        // 3.75 => 4.00
        if (decimalPortionNumber > 74) return Number((intPart + 1).toFixed(2))
        // 3.25 or 3.74 => 3.50
        return Number((intPart + 0.5).toFixed(2))
      }

      // If whole-rounding, use the normal round method
      if (this.salePriceRoundMethod === 'whole-rounding') return Math.round(salePrice)

      // If no-rounding return the salePrice as-is
      return salePrice
    },
    setPaginationTotalPages(totalDisplayedRows) {
      this.$store.state.paginationTotalPages = Math.ceil(totalDisplayedRows / this.totalRowsOnPage)
    },
  },
}
</script>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.innerVerticalBorder {
  border-left: 1px solid;
}
.innerVerticalBorder:first-child {
  border-left: 0px;
}
.innerHorizontalBorder {
  border-top: 1px solid;
}
.innerHorizontalBorder:first-child {
  border-top: 0px;
}
.lowerHorizontalBorder {
  border-bottom: 1px solid;
}
.singleLineHeight {
  height: 24px;
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 430px) {
  .sticky {
    top: 126px;
  }
}
@media only screen and (min-width: 431px) {
  .sticky {
    top: 132px;
  }
}
.green-border >>> fieldset {
  border: 1px solid #4caf50 !important;
}
.cell-border {
  border-right: 1px solid #aaa !important;
  border-bottom: 1px solid #aaa !important;
}
.header {
  border: 1px solid #aaa !important;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
</style>
