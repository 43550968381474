<template>
  <v-dialog persistent :value="true" max-width="500">
    <v-card>
      <v-card-title class="caption info white--text pa-1 d-flex justify-center">
        <div>New products!</div>
      </v-card-title>
      <v-container>
        <v-card-text>
          {{ swT('brandupdated') }}
        </v-card-text>
        <div class="ma-2 text-center">
          <v-btn style="width: 100%" x-large color="red" dark outlined @click.native="$emit('operation', { showProducts: true })">
            <v-icon left>mdi-tag</v-icon>
            {{ swT('visitproductspage') }}
          </v-btn>
        </div>
        <div class="ma-2 text-center">
          <v-btn style="width: 100%" x-large color="red" dark outlined @click.native="$emit('operation', { cancel: true })">
            <v-icon left>mdi-clock</v-icon>
            {{ swT('checklater') }}
          </v-btn>
        </div>
        <div class="ma-2 text-center">
          <v-btn style="width: 100%" x-large color="red" dark outlined @click.native="$emit('operation', { noSync: true })">
            <v-icon left>mdi-sync-off</v-icon>
            {{ swT('dontSync') }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  data() {
    return {
      swT,
      // todo
    }
  },
  deactivated() {
    this.$emit('closeUpdatedBrandPopup')
    this.$destroy()
  },
}
</script>
