import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swProductCard',{attrs:{"hover":_vm.hover,"product":_vm.selectedProduct},scopedSlots:_vm._u([{key:"card-header",fn:function(ref){
var product = ref.product;
return [_c('div',{staticClass:"px-0 d-flex align-center",class:_vm.headerClass(product.usedByTenant),staticStyle:{"height":"36px"},on:{"click":function($event){return _vm.handleShowYesNoDialog(product.usedByTenant)}}},[(product.usedByTenant !== true && !_vm.isDemo)?_c('div',{staticStyle:{"width":"20px"}},[_vm._v(" ")]):_vm._e(),_c('div',{staticClass:"ellipsis caption white--text"},[_c('span',[_vm._v(_vm._s(product.articleCodeSupplier))])]),(product.usedByTenant !== true && !_vm.isDemo && !_vm.isAdmin)?_c(VIcon,{staticClass:"headerIcon",attrs:{"dark":"","dense":""}},[_vm._v(" mdi-cloud-download-outline ")]):_vm._e()],1)]}},{key:"card-image",fn:function(ref){
var product = ref.product;
return [_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"height":"200px"},on:{"click":_vm.imageAction}},[(product.IMAGES[0])?_c(VImg,{attrs:{"contain":"","height":"200","width":"150","src":_vm.imageSrc(product.IMAGES[0].url, 150)}}):(_vm.groupIconName(product))?_c(VImg,{attrs:{"src":require(("../assets/groupsIcons/" + (_vm.groupIconName(product)) + ".svg")),"contain":"","width":"100"}}):_vm._e()],1)]}}])}),_c('swYesNoDialog',{attrs:{"show-remember":true,"message":_vm.dialogMessage},on:{"yes":_vm.handleYes,"no":function($event){_vm.showYesNoDialog = false},"remember":_vm.rememberConfirmation},model:{value:(_vm.showYesNoDialog),callback:function ($$v) {_vm.showYesNoDialog=$$v},expression:"showYesNoDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }