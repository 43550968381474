<template>
  <div>
    <div v-if="isNotFromLatestCollection">
      <swMatrix :headers="matrix.headers" :rows="matrix.rows" :show-eye="true" :can-set-all-cell-values="true" @update="updateCell" />
      <v-row class="pt-3">
        <v-col align="right" class="pr-1">
          <v-btn class="mr-2" color="primary" @click.stop="printBarcodes()">
            <v-icon x-large>mdi-barcode</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="text-center pt-4 font-weight-light">{{ swT('importarticle') }}</div>
    </div>
    <swPrintDialog v-model="printDialog" :print-buffer="printBuffer" @close-print-dialog="printDialog = false" @ok="printDialog = false" />
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '@/store/globalStore'
import productFunctions from '../functions/products'
import swMatrix from '../components/swMatrix.vue'
import swPrintDialog from '../components/swPrintDialog.vue'
import print from '../functions/print'
import { eventBus } from '../main'

export default {
  components: {
    swMatrix,
    swPrintDialog,
  },
  props: ['product'],
  data() {
    return {
      swT,
      barcodesToPrint: {},
      printDialog: false,
      printBuffer: [],
    }
  },
  computed: {
    isNotFromLatestCollection() {
      return this.product.source !== 'latestCollection' || this.product.usedByTenant
    },
    matrix() {
      return productFunctions.buildMatrices(
        this.product.SKUS,
        globalStore.getLatestCollectionObject('sku'),
        globalStore.getIndexedBrandProducts(),
        (product) => product.SKUS[0].articleCodeSupplier,
        globalStore.getMetadata().sizeOrdering.data,
        this.$store.state.activeConfig.products.mapSubSizes.value
      )[0].MATRIX
    },
  },
  methods: {
    updateCell(key, value) {
      this.barcodesToPrint[key] = value
    },
    async printBarcodes() {
      const skus = globalStore.getLatestCollectionObject('sku')
      const printBuffer = Object.entries(this.barcodesToPrint)
        .filter((entry) => entry[1] > 0)
        .map((entry) => {
          const barcode = entry[0]
          const qty = entry[1]
          const sku = skus[barcode]
          if (sku == undefined) {
            const skuFound = this.product.SKUS.find((sku) => sku.barcode === barcode)
            const downloadedSku = this.saveProductToTenantDb(skuFound)
            if (downloadedSku) return print.sku4BarcodeLabel(skuFound, qty)
          } else {
            return print.sku4BarcodeLabel(sku, qty)
          }
        })
      if (printBuffer.length == 0) {
        this.$store.dispatch('raiseAlert', {
          header: 'nothingtoprint',
          type: 'warning',
          timeout: 5000,
        })
        return
      }
      this.printBuffer = printBuffer
      this.printDialog = true
      eventBus.$emit('setAllMatrixQuantities')
      this.barcodesToPrint = {}
    },
    async saveProductToTenantDb(sku) {
      this.$store.state.loading = true
      const succeeded = await this.$store.dispatch('updateSkuObjects', { data: [sku], audit: 'Add sku to tenant DB for reorder' })
      this.$forceUpdate()
      this.$store.state.loading = false
      return succeeded
    },
  },
}
</script>
