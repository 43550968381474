<template>
  <v-dialog :value="value" max-width="500" @input="$emit('input')">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ swT('print-jobs') }}
      </v-card-title>
      <v-card-text class="my-8">
        <v-row v-for="job in jobs" :key="job">
          <v-col>
            <v-btn style="width: 100%" x-large color="info" dark :data-test="`print-btn-${job}`" @click.native="print(job)">
              {{ job }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="campaigns.length > 0">
          <v-col>
            <v-select v-model="selectedCampaign" class="mt-2" data-test="selectCampaign" dense outlined hide-details :items="campaigns" :label="swT('applyCampaign')"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="selectedDevice" class="mt-2" data-test="selectPrinter" dense outlined hide-details :items="devices" :label="swT('select_printer')"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-test="okButton" color="info" large text @click.native="$emit('ok')">
          {{ swT('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function fixFloat(f) {
  if (!f.toFixed) return f
  return parseFloat(f.toFixed(2))
}
import { swT } from '@/functions/i18n'
import tools from '../functions/tools'
import print from '../functions/print'
import globalStore from '../store/globalStore'
export default {
  props: ['value', 'printBuffer'],
  data() {
    return {
      swT,
      selectedCampaign: '',
      selectedDevice: '',
    }
  },
  computed: {
    devices() {
      return [
        { text: 'USB Printer', value: 'zplDirect' },
        { text: 'PDF Download', value: 'pdfDownload' },
        { text: 'ZPL Preview', value: 'zplPreview' },
        ...this.$store.state.activeConfig.labelPrinting.printQueues.value.map((q) => ({
          text: q.substring(0, q.indexOf(':')),
          value: q,
        })),
      ]
    },
    jobs() {
      const defaultLayouts = this.$store.state.activeConfig.labelPrinting.pricetagLayoutDefaults.value
      const jobs = tools.getUniqueValues(this.printBuffer.map((s) => s.pricetagLayouts.split(',')).flat())
      const bufferHasCustomLayout = jobs.length > 1 || (jobs.length === 1 && jobs[0] !== 'pricetag')
      const tenantPricetagLayouts = globalStore.getItems('pricetagLayouts')

      return defaultLayouts.length === 0 && !bufferHasCustomLayout ? tenantPricetagLayouts : jobs
    },
    campaigns() {
      const campaigns = globalStore.getLatestCollectionObject('campaign')
      const allCampaignsInTheBuffer = this.printBuffer.map((s) => s.campaigns.split(',')).flat()
      const campaignItems = tools
        .getUniqueValues(allCampaignsInTheBuffer)
        .map((e) => {
          if (!campaigns[e] || !campaigns[e].active) return
          return { value: e, text: campaigns[e]?.campaignName }
        })
        .filter((e) => e !== undefined)
      const noCampaign = { text: swT('no_active_campaign'), value: null }
      campaignItems.push(noCampaign)
      return campaignItems
    },
  },
  created() {
    this.selectedDevice = this.$store.state.activeConfig.labelPrinting.labelPrintingStrategy.value
  },
  deactivated() {
    this.$emit('close-print-dialog')
    this.$destroy()
  },
  methods: {
    async print(job) {
      if (this.selectedCampaign) {
        const campaign = globalStore.getLatestCollectionObject('campaign')[this.selectedCampaign]
        this.printBuffer.forEach((buffer) => {
          switch (campaign.discountRule) {
            case 'percentage':
              buffer.salePrice = fixFloat((buffer.price * (100 - campaign.parameter)) / 100)
              break
            case 'amount':
              buffer.salePrice = buffer.price - campaign.parameter
              break
            case 'replace':
              buffer.salePrice = campaign.parameter
              break
          }
        })
      }

      const printStrategyParameters = {
        printQueueName: 'none',
      }

      let printStrategy = this.selectedDevice
      const splittedPrintStrategy = printStrategy.split(':')
      if (splittedPrintStrategy.length == 2) {
        printStrategyParameters.queueName = splittedPrintStrategy[1]
        printStrategy = 'unknown'
        if (splittedPrintStrategy[0].includes('ZPL')) printStrategy = 'zplPrintQueue'
        if (splittedPrintStrategy[0].includes('PDF')) printStrategy = 'pdfPrintQueue'
      }

      if (!(await print.printBarcodes({ skus: this.printBuffer }, job, this.$store, printStrategy, printStrategyParameters)))
        this.$store.dispatch('raiseAlert', {
          header: 'printingcanceled',
          type: 'warning',
          timeout: 3000,
        })
    },
  },
}
</script>
