<template>
  <div>
    <h1>{{ swT('history') }}</h1>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis, praesentium! Inventore consectetur iste ratione delectus repellat assumenda suscipit, numquam iure dolorem
      amet cupiditate ducimus quisquam illo omnis obcaecati voluptatum nobis! Perspiciatis tempora voluptatum itaque, voluptate dolores doloremque quibusdam sit libero laudantium.
      Recusandae nostrum animi beatae deleniti laboriosam, magni eos harum. Earum, pariatur ipsa impedit in labore adipisci suscipit cum enim? Iste laboriosam iure commodi
      laudantium accusantium ab aperiam tenetur repudiandae, sed dicta suscipit saepe odit vitae pariatur. Aliquid odit rem quas deleniti iusto blanditiis vel quam? Sint
      voluptatibus tempora fugit! Nam quam, obcaecati velit a dolorem alias porro harum placeat, possimus vero voluptate dolor esse, illo ratione. Esse possimus a delectus maiores
      quod cupiditate suscipit nulla, vitae debitis aspernatur laboriosam? Dignissimos aliquid consequatur eum deleniti perferendis distinctio labore explicabo illo facere odit
      iste fugiat omnis nisi exercitationem asperiores atque temporibus, alias, debitis enim laboriosam autem molestias nesciunt, dolor natus! Eius. A animi repellendus nisi
      nostrum! Quia eius sed nulla debitis labore suscipit eligendi necessitatibus illum recusandae? Eos, fugit. Delectus, autem necessitatibus quis ex illo quaerat vitae sapiente
      dolorem ratione. Impedit? Adipisci, dolorem nam asperiores placeat impedit et, accusantium autem, beatae deserunt incidunt esse ex veniam laboriosam. Quas asperiores qui,
      dolore error aut non at, doloremque, perferendis dolor libero dolores officiis! Aut quibusdam, soluta excepturi eveniet adipisci alias, eaque quaerat facilis, iure ex aliquid
      eligendi! Dolore quis voluptas fugit. Ipsam quaerat excepturi fuga fugit dolores, quasi maxime aliquam ea soluta earum! Tempore alias voluptas ea quo expedita impedit quod
      doloribus ducimus harum iure atque mollitia commodi nulla deserunt laudantium veritatis, sunt ipsa sit nostrum eligendi numquam. Quaerat provident quod ducimus quo. Sit,
      sequi laudantium quia perspiciatis ea corporis tenetur, mollitia enim odit obcaecati autem nostrum pariatur incidunt doloremque? Labore ut, quae nam eum temporibus quia quas
      harum voluptates fugiat eos dignissimos!
    </p>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['product'],
  data() {
    return {
      swT,
    }
  },
  created() {
    // Do something
  },
  methods: {},
}
</script>
