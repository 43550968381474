<template>
  <v-dialog persistent transition="dialog-bottom-transition" :value="true" max-width="80vw" class="white">
    <v-card class="pb-2 white">
      <v-card class="sticky-element px-2 mb-2 mx-0" height="96px">
        <v-card-text>
          <v-row data-test="brand-title mx-1 px-0">
            <v-col class="d-flex align-center justify-start">
              <div class="text-h5" data-test="brand-title">{{ brand.name }}</div>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-img :src="brand.url || require('../assets/barcode-logo.png')" class="configure-image" contain max-width="100"></v-img>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <div class="py-1 d-flex justify-center">
                <v-btn color="mx-2 green lighten-1" dark data-test="saveconfigbutton" @click.native="saveConfig()">{{ swT('save') }}</v-btn>
                <v-btn color="mx-2 red lighten-1" dark data-test="cancelconfigbutton" @click.native="$emit('operation', { cancel: true })">{{ swT('cancel') }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-form ref="form" v-model="valid" lazy-validation color="white">
        <v-card v-if="!hasRole('products-dataprovider_admin')" class="mx-4">
          <v-card-text v-if="brand.www || brand.email" class="px-0">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list dense hide-details flat>
                  <v-list-item-group>
                    <v-list-item v-if="brand.www" @click="openTab(brand.www)">
                      <v-list-item-icon>
                        <v-icon>mdi-web</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>{{ brand.www }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="brand.email" @click="mailTo(brand.email)">
                      <v-list-item-icon>
                        <v-icon>mdi-email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>{{ brand.email }}</v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="pb-0">
            <h2>{{ swT('brand_settings') }}</h2>
          </v-card-text>

          <v-card-text class="px-0">
            <swAuth role="sw">
              <v-row class="ma-0 pa-0 d-flex align-center">
                <v-col cols="12" sm="3" class="d-flex justify-space-between">
                  <span class="ml-2" :class="brand.hideBrand ? 'text--primary' : 'text--disabled'">{{ swT('hide_brand') }}</span>
                  <v-switch v-model="brand.hideBrand" class="mr-2" data-test="hide-brand" inset hide-details></v-switch>
                  <span class="ml-2" :class="brand.isSupplier ? 'text--primary' : 'text--disabled'">{{ swT('is_supplier') }}</span>
                  <v-switch v-model="brand.isSupplier" class="mr-2" data-test="is-supplier" inset hide-details></v-switch>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field v-model="brand.url" dense hide-details outlined :label="swT('logourl')" @change="completeBrandLogoUrl($event, brand)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="brand.name"
                    data-test="brand-name"
                    dense
                    hide-details
                    outlined
                    :rules="[(v) => !!v || swT('required')]"
                    :label="swT('name')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field v-model="brand.collection" dense hide-details outlined readonly disabled :label="swT('collection')"></v-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field v-model="brand.www" dense outlined data-test="wwwfield" :label="swT('www')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field v-model="brand.email" dense outlined data-test="emailfield" :label="swT('email')"></v-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="brand.gln"
                    dense
                    outlined
                    :rules="[(v) => !v || v.length == 13 || v.length == 12 || swT('lengthshouldbe12or13'), (v) => !isNaN(v) || swT('shouldbenumber')]"
                    :label="swT('gln')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select v-show="!brand.isSupplier" v-model="brand.supplier" dense outlined :label="swT('supplier')" :items="getItems('mastersuppliers')"></v-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select
                    v-model="brand.orderMethods"
                    dense
                    hide-details
                    outlined
                    data-test="ordermethodsselector"
                    small-chips
                    multiple
                    :items="['LatestCollection', 'email', 'EDI', 'API', 'StockBase']"
                    :label="swT('ordermethods')"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="brand.purchaseOrderEmail"
                    dense
                    hide-details
                    outlined
                    data-test="purchaseOrderEmailfield"
                    :label="swT('purchaseorder_email')"
                  ></v-text-field>
                </v-col>

                <v-col v-show="!brand.isSupplier" cols="12" sm="12">
                  <v-select
                    v-model="brandDataProviders"
                    dense
                    hide-details
                    outlined
                    data-test="dataproviders"
                    small-chips
                    multiple
                    :items="dataProviders"
                    :label="swT('dataProviders')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-show="!brand.isSupplier" class="ma-0 pa-0">
                <v-col cols="12" sm="3">
                  <v-combobox v-model="brand.aliases" dense hide-details outlined small-chips multiple deletable-chips :label="swT('aliases')" data-test="aliasfield"></v-combobox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-combobox
                    v-model="brand.authGroups"
                    dense
                    hide-details
                    outlined
                    small-chips
                    multiple
                    deletable-chips
                    :label="swT('authgroups')"
                    data-test="authgroupsfield"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row v-show="!brand.isSupplier" class="ma-0 pa-0">
                <v-col cols="12" sm="3">
                  <v-combobox
                    v-model="brand.whiteListTenants"
                    dense
                    hide-details
                    outlined
                    small-chips
                    multiple
                    deletable-chips
                    data-test="whitelistfield"
                    :label="swT('whitelisttenants')"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field v-model="brand.externalLink" dense hide-details outlined data-test="externalLinkField" :label="swT('external_link')"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col>
                  <v-list-item v-if="brand.externalLink" @click="openTab(brand.externalLink)">
                    <v-list-item-icon>
                      <v-icon>mdi-book-open-page-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ brand.externalLink }}
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-show="!brand.isSupplier"
                    v-model="brand.ownerTenantId"
                    dense
                    hide-details
                    outlined
                    data-test="ownerTenantIdField"
                    :label="swT('ownerTenantId')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <template v-slot:otherwise>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="brand.calculation"
                      data-test="calculationfield"
                      dense
                      outlined
                      type="number"
                      min="0.00"
                      step="0.1"
                      :label="swT('calculation')"
                      :rules="[numberRules.mustBeNumber, numberRules.notNegative]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="brand.discount"
                      data-test="discountfield"
                      dense
                      outlined
                      type="number"
                      min="0"
                      step="0.1"
                      :label="swT('discount%')"
                      :rules="[numberRules.mustBeNumber, numberRules.notNegative]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="brand.freeShippingThreshold"
                      data-test="freeShippingThresholdfield"
                      dense
                      outlined
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-eur"
                      :label="swT('free_shipping_threshold')"
                      :rules="[numberRules.mustBeNumber, numberRules.notNegative]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="brand.orderMethod"
                      data-test="ordermethodfield"
                      dense
                      hide-details
                      outlined
                      clearable
                      :items="brand.orderMethods"
                      :label="swT('ordermethod')"
                    ></v-select>
                  </v-col>
                  <v-col v-if="$store.state.activeConfig.reports.articlestatus_footprint.value" cols="12" sm="3">
                    <v-text-field
                      v-model="brand.footprint"
                      data-test="footprintfield"
                      dense
                      hide-details
                      outlined
                      type="number"
                      min="0.00"
                      :label="swT('footprint_in_shop')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="brand.orderMethod == 'API' && brandSpecificApiSettings[brand.collection]" class="ma-1">
                  <v-col cols="12">
                    <h2>{{ swT('api_settings') }}</h2>
                  </v-col>
                  <v-col v-for="setting of brandSpecificApiSettings[brand.collection]" :key="setting.label" cols="12">
                    <v-text-field
                      v-if="setting.type == 'text'"
                      v-model="brand.orderMethodConfiguration[setting.field]"
                      dense
                      hide-details
                      outlined
                      :rules="[(v) => !!v || swT('required')]"
                      :label="swT(setting.label)"
                      required
                    ></v-text-field>
                    <v-alert v-if="setting.type == 'alert'" :type="setting.color" class="mb-0 pa-5 alert">
                      <b>{{ swT(setting.header) }}</b>
                      <p v-if="setting.body">{{ swT(setting.body) }}</p>
                    </v-alert>
                  </v-col>
                </v-row>
              </template>
            </swAuth>
          </v-card-text>
        </v-card>

        <v-row class="ma-0 px-4">
          <swPropertyMapping :mapping="brand.propertyMapping" :brand="brand.collection" />
        </v-row>
        <v-row v-if="!hasRole('products-dataprovider_admin,sw')" class="ma-0 px-4">
          <swBrandOwnership :brand="brand" />
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '../store/globalStore'
import consts from '../store/consts'
import brandSpecificApiSettings from '../store/brandSpecificApiSettings'
import axios from 'axios'
import swPropertyMapping from '../components/swPropertyMapping.vue'
import swBrandOwnership from '../components/swBrandOwnership.vue'
import { hashBrand } from '@softwear/latestcollectioncore'
import userFunctions from '../functions/users'
export default {
  components: { swPropertyMapping, swBrandOwnership },
  props: ['brand'],
  data() {
    return {
      swT,
      valid: false,
      brandDataProviders: null,
      numberRules: {
        mustBeNumber: (v) => !isNaN(v) || swT('set_to_zero_not_empty'),
        notNegative: (v) => !v || parseInt(v) >= 0 || swT('requirepositive'),
      },
      brandSpecificApiSettings: brandSpecificApiSettings,
    }
  },
  computed: {
    purchaseOrders() {
      const allPurchaseOrders = globalStore.getLatestCollectionArray('purchaseorder')
      const brand = this.brand.name.toLowerCase()
      return allPurchaseOrders.filter((purchaseOrder) => purchaseOrder.brand == brand)
    },
    dataProviders() {
      return Object.keys(this.$store.state.activeConfig.partnerchannels)
    },
  },
  watch: {
    brandDataProviders(newValue) {
      this.brand.dataProviders = {}
      newValue.forEach((provider) => {
        this.brand.dataProviders[provider] = {
          count: 0,
          lasttime: 0,
        }
      })
    },
  },
  created() {
    if (!this.brand.dataProviders) this.brand.dataProviders = {}
    if (!this.brand.orderMethodConfiguration) this.$set(this.brand, 'orderMethodConfiguration', {})
    this.brandDataProviders = Object.keys(this.brand.dataProviders)
    if (!this.brand.propertyMapping) this.$set(this.brand, 'propertyMapping', [])
    this.brand.propertyMapping.push({ category: 'Collection', from: '', to: '' })
    this.brand.propertyMapping.push({ category: 'ColorCode', from: '', to: '' })
    this.brand.propertyMapping.push({ category: 'ColorDescription', from: '', to: '' })
    this.brand.propertyMapping.push({ category: 'ProductGroup', from: '', to: '' })
    this.brand.propertyMapping.push({ category: 'Size', from: '', to: '' })
    // sort the empty 'from' lines to the bottom
    this.brand.propertyMapping.sort((a, b) => (a.category + (a.from ? a.from : '~') > b.category + (b.from ? b.from : '~') ? 1 : -1))
  },
  deactivated() {
    this.$emit('close-configure-brand-popup')
    this.$destroy()
  },
  methods: {
    saveConfig() {
      if (this.valid) {
        this.hashAliases()
        this.$emit('operation', { save: true })
      }
    },
    hasRole(role) {
      return userFunctions.hasRole(this.$store.getters.roles, role)
    },
    hashAliases() {
      if (!this.brand.aliases) return
      this.brand.aliases = this.brand.aliases.map(hashBrand).filter((alias) => alias != this.brand.collection)
    },
    noBrandLogo(brand) {
      this.$store.dispatch('raiseAlert', {
        header: 'logonotfound',
        type: 'warning',
        timeout: 3000,
      })
      brand.url = ''
    },
    async completeBrandLogoUrl(event, brand) {
      brand.url = event
      if (event.substring(0, 4) != 'http') brand.url = consts.latestCollection.brandLogosUrl + '/' + event
      try {
        const response = await axios.get(brand.url)
        if (response.request.responseURL != brand.url) this.noBrandLogo(brand)
      } catch {
        this.noBrandLogo(brand)
      }
    },
    openTab(url) {
      if (url.substring(0, 4) != 'http') url = 'https://' + url
      window.open(url, '_blank')
    },
    mailTo(email) {
      email = 'mailto:' + email
      window.open(email, '_blank')
    },
    getItems(items) {
      return globalStore.getItems(items, this.$store)
    },
  },
}
</script>

<style scoped>
.sticky-element {
  top: 0;
  position: sticky !important;
  z-index: 4;
  border-bottom: 1px solid #0003;
  border-radius: 0;
}
.configure-image {
  max-height: 4vh;
}
</style>
