<template>
  <v-dialog :value="value" max-width="500" @input="$emit('input')">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ swT('info') }}
      </v-card-title>
      <v-card-text class="my-4">
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" large text @click.native="ok">{{ swT('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['value', 'message', 'routeOnOK'],
  data() {
    return {
      swT,
      // todo
    }
  },
  deactivated() {
    this.$emit('close-info-dialog')
    this.$destroy()
  },
  methods: {
    ok() {
      this.$emit('input')
      this.$router.push({
        path: this.routeOnOK,
      })
    },
  },
}
</script>
