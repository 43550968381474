import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","value":true,"max-width":"500"}},[_c(VCard,[_c(VCardTitle,{staticClass:"caption info white--text pa-1 d-flex justify-center"},[_c('div',[_vm._v("New products!")])]),_c(VContainer,[_c(VCardText,[_vm._v(" "+_vm._s(_vm.swT('brandupdated'))+" ")]),_c('div',{staticClass:"ma-2 text-center"},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"red","dark":"","outlined":""},nativeOn:{"click":function($event){return _vm.$emit('operation', { showProducts: true })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(_vm.swT('visitproductspage'))+" ")],1)],1),_c('div',{staticClass:"ma-2 text-center"},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"red","dark":"","outlined":""},nativeOn:{"click":function($event){return _vm.$emit('operation', { cancel: true })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.swT('checklater'))+" ")],1)],1),_c('div',{staticClass:"ma-2 text-center"},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"red","dark":"","outlined":""},nativeOn:{"click":function($event){return _vm.$emit('operation', { noSync: true })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-sync-off")]),_vm._v(" "+_vm._s(_vm.swT('dontSync'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }