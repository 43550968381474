import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"brandCardDiv",staticClass:"brand-card",staticStyle:{"cursor":"pointer"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:_vm.staticClasses,style:(_vm.borderStyle.border),attrs:{"elevation":hover ? 20 : 4}},[(_vm.isUpdated)?_c(VBadge,{attrs:{"bordered":"","color":"error","icon":"mdi-exclamation","overlap":""}}):_vm._e(),_c('div',{staticClass:"icons-container"},[(_vm.showLock)?_c('div',{staticClass:"overlay",attrs:{"data-test":"lock-icon-container"}},[_c(VIcon,{staticClass:"overlay-icon",attrs:{"size":"25"}},[_vm._v("mdi-lock")])],1):_vm._e(),(_vm.showUnlocked)?_c('div',{staticClass:"overlay",attrs:{"data-test":"unlocked-icon-container"}},[_c(VIcon,{staticClass:"overlay-icon",attrs:{"size":"25"}},[_vm._v("mdi-lock-open")])],1):_vm._e(),(_vm.showNoBarcodes)?_c('div',{staticClass:"overlay",attrs:{"data-test":"no-barcode-icon-container"}},[_c(VIcon,{staticClass:"overlay-icon",attrs:{"size":"25"}},[_vm._v("mdi-barcode-off")])],1):_vm._e()]),(!hover && _vm.brand.url)?_c(VImg,{staticClass:"brand-card__logo mt-2",attrs:{"contain":"","src":_vm.brand.url}}):_vm._e(),(hover || !_vm.brand.url)?_c('div',{staticClass:"text-center brand-card__text font-weight-regular px-2",class:!hover ? 'text-truncate text-h6' : '',style:((hover ? _vm.adjustFontSize(_vm.brand.name) : '') + _vm.borderStyle.color)},[_c('span',[_vm._v(_vm._s(_vm.brand.name))])]):_vm._e(),(!hover && !_vm.brand.url)?_c(VImg,{staticClass:"brand-card__logo--sw",attrs:{"contain":"","src":require("../assets/barcode-logo.png")}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }