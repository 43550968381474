<template>
  <div class="mt-4 justify-center">
    <div v-if="noProducts">
      <p>
        {{ swT('noproductsforfilter') }}
      </p>
    </div>
    <v-list>
      <v-list-item-group class="d-flex flex-wrap">
        <template v-for="(item, i) in products">
          <v-list-item :key="`item-${i}`" :value="item.articleCode" active-class="ext--accent-4" @click="clickRow(item)">
            <template v-slot:default>
              <v-list-item-content :class="productClass(item)"></v-list-item-content>

              <v-list-item-content class="px-2">
                <v-list-item-title v-text="item.articleCodeSupplier"></v-list-item-title>
                <v-list-item-subtitle v-text="item.articleDescription"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block">
                <v-list-item-title v-text="item.collection"></v-list-item-title>
                <v-list-item-subtitle v-text="item.brand"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block">
                <v-list-item-title>
                  <swCurrency v-if="item.suggestedRetailPrice == undefined || item.suggestedRetailPrice == 0" :amount="item.price" class="green--text" />
                  <swCurrency v-else :amount="item.price" class="red--text strikethrough" />
                </v-list-item-title>
                <v-list-item-subtitle>
                  <swCurrency v-if="item.suggestedRetailPrice != 0" :amount="item.suggestedRetailPrice" class="green--text" />
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-sm-block text-right">
                <v-list-item-title v-text="item.articleGroup"></v-list-item-title>
                <v-list-item-subtitle>
                  <swCurrency :amount="item.buyPrice" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['value', 'products', 'brandId', 'selectedProducts', 'loaderDialog'],
  data() {
    return {
      swT,
      lastClickIndex: 0,
    }
  },
  computed: {
    noProducts() {
      return this.products.length == 0 && this.loaderDialog == false
    },
  },
  methods: {
    productClass(product) {
      return {
        warning: product.source == 'tenant',
        info: product.usedByTenant,
        green: product.source == 'latestCollection',
        product: true,
      }
    },
    clickRow(product) {
      this.$emit('update:selected', product)
    },
  },
}
</script>
<style scoped>
.product-row {
  border-left: 1px solid black;
  margin-left: -1px;
  border-right: 1px solid black;
  margin-right: -1px;
  height: 1.2%;
}
.outerbox {
  height: 310px;
  width: 160px;
}
.innerbox {
  margin: auto;
  height: 200px;
  overflow: hidden;
}
.v-list-item {
  padding: 0;
}
.product {
  max-width: 5px;
  height: 100%;
}
.opacity {
  opacity: 0.6;
}
</style>
