<template>
  <v-dialog :value="showChooseNewSkuMethod" persistent max-width="500" @input="$emit('input')">
    <v-card>
      <v-card-title class="headline info white--text py-4">
        <v-spacer></v-spacer>
        <v-btn data-test="cancelButton" color="info" large @click.native="closeDialog">{{ swT('cancel') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-card
          class="my-6"
          color="green lighten-3 d-flex align-center justify-center"
          style="cursor:pointer; min-height: 80px"
          data-test="scanBarcodes"
          @click.native="addBarcodes()"
        >
          <v-card-text class="pl-6">
            <div class="text-h5 text-center">
              {{ swT('product_has_barcode') }}
            </div>
          </v-card-text>
        </v-card>

        <v-card color="warning lighten-3 d-flex align-center" style="cursor:pointer; min-height: 80px" data-test="isGeneratingBarcodes" @click.native="generateBarcodes()">
          <v-card-text class="px-6">
            <div class="text-h5 text-center">
              {{ swT('product_has_no_barcode') }}
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import { eventBus } from '../main'
export default {
  props: ['showChooseNewSkuMethod', 'skuToCopy'],
  data() {
    return {
      swT,
    }
  },
  deactivated() {
    this.$emit('close-new-sku-method')
    this.$destroy()
  },
  methods: {
    addBarcodes() {
      eventBus.$emit('resetSwSkuEditor')
      eventBus.$emit('addSkuMethod', { method: 'scan', skuToCopy: this.skuToCopy })
      eventBus.$emit('closeChooseNewSkuMethod')
    },
    generateBarcodes() {
      eventBus.$emit('resetSwSkuEditor')
      eventBus.$emit('addSkuMethod', { method: 'generate', skuToCopy: this.skuToCopy })
      eventBus.$emit('closeChooseNewSkuMethod')
    },
    closeDialog() {
      eventBus.$emit('closeChooseNewSkuMethod')
    },
  },
}
</script>
