<template>
  <div class="d-flex flex-wrap mt-4 justify-center">
    <div v-if="hasProducts">
      <p>
        {{ swT('noproductsforfilter') }}
      </p>
    </div>
    <div v-for="product in products" v-else :key="product.articleCode" :data-test="'prod-' + product.articleCode" class="ma-1">
      <v-hover v-slot:default="{ hover }">
        <swProductCardView :selected-product="product" :hover="hover" @update:selected="$emit('update:selected', $event)" />
      </v-hover>
    </div>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import animations from '../functions/animations'
import swProductCardView from '../components/swProductCardView.vue'

export default {
  components: {
    swProductCardView,
  },
  props: ['products', 'loaderDialog'],
  data() {
    return {
      swT,
      // todo
    }
  },
  computed: {
    hasProducts() {
      return this.products.length == 0 && this.loaderDialog == false
    },
  },
  updated() {
    animations.cardStagger()
  },
  activated() {
    animations.cardStagger()
  },
}
</script>
