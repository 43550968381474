<template>
  <v-card v-if="$store.state.user.current_tenant !== 'master'" style="width:100%;" :flat="!brand.ownerTenantId">
    <v-card-text>
      <v-row v-if="!brand.ownerTenantId">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="info" data-test="requestBrandOwnership" :disabled="disableClaimBrand" @click.native="requestBrandOwnership(brand)">
            <v-icon left>mdi-alert</v-icon>
            {{ disableClaimBrand ? swT('request_brand_ownership_already_sent') : swT('request_brand_ownership') }}
          </v-btn>
        </v-col>
      </v-row>
      <div v-else-if="brand.ownerTenantId == $store.state.user.current_tenant">
        <v-row>
          <h2 style="padding-top:10px;padding-left: 10px;">
            {{ swT('brand_ownership') }}
            <v-chip>
              {{ brandVisibility ? swT('private') : swT('public') }}
            </v-chip>
          </h2>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-btn :color="brandVisibility ? 'secondary' : 'primary'" data-test="setBrandToPrivate" @click="showYesNoDialog">
              <v-icon left>{{ brandVisibility ? 'mdi-lock-open-outline' : 'mdi-lock' }}</v-icon>
              {{ brandVisibility ? swT('public') : swT('private') }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex">
            <p class="warning-text">
              {{ swT('switch_brand_visibilty_explanation') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <h4 style="padding-left: 10px;">{{ swT('add_new_associate') }}</h4>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field v-model="associatestTenantID" dense outlined data-test="associateIdLinkField" :label="swT('associate_id')" hide-details></v-text-field>
          </v-col>
          <v-col cols="12" sm="5">
            <v-select v-model="associateStatus" dense outlined data-test="associateStatusLinkField" :items="statuses" :label="swT('associat_status')" hide-details></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn color="primary" data-test="addNewAssociateBttn" @click="manageAssociates">
              <v-icon left>mdi-account-plus</v-icon>
              {{ swT('save') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="brandAssociates">
          <v-col>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense outlined hide-details class="my-3"></v-text-field>
            <v-data-table :headers="headers" :items="brandAssociates" :search="search" :items-per-page="5" dense @click:row="editAssociate"></v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <swYesNoDialog v-model="confirmBrandVisibiltyDialog" :message="dialogMessage" style="z-index: 250;" @yes="changeBrandVisibility" @no="handleDialogNo" />
  </v-card>
</template>

<script>
import { swT } from '@/functions/i18n'
import webServices from '../functions/webServicesFacade'
import events from '../functions/events'
import swYesNoDialog from '../components/swYesNoDialog.vue'

export default {
  components: {
    swYesNoDialog,
  },
  props: ['brand'],
  data() {
    return {
      swT,
      valid: false,
      statuses: ['approved', 'declined'],
      search: '',
      headers: [
        { text: 'TenantID', value: 'tenantId' },
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'emails', value: 'emails' },
      ],
      associatestTenantID: '',
      associateStatus: '',
      brandAssociates: [],
      brandVisibility: false,
      disableClaimBrand: false,
      confirmBrandVisibiltyDialog: false,
      dialogMessage: swT('set_brand_to_private'),
    }
  },
  computed: {
    changeVisibilityReadonly() {
      return this.brand.associates?.length > 0
    },
  },
  async beforeMount() {
    await this.getTenantsWithOwners()
    this.checkBrandVisibilty()
    await this.showClaimBrandButton()
  },
  methods: {
    editAssociate(associate) {
      this.associatestTenantID = associate.tenantId
      this.associateStatus = associate.status
    },
    manageAssociates() {
      if (this.associatestTenantID && this.associateStatus) {
        const selectedAssociate = { tenantId: this.associatestTenantID, status: this.associateStatus }
        const foundAssociate = this.brandAssociates?.find((associate) => associate.tenantId === selectedAssociate.tenantId)

        if (!foundAssociate) this.addNewAssociate(selectedAssociate)
        else this.updateAssociate(foundAssociate, selectedAssociate)

        this.associatestTenantID = ''
        this.associateStatus = ''
      }
    },
    addNewAssociate(newAssociate) {
      if (this.brandAssociates) this.brandAssociates.push(newAssociate)
      else this.brandAssociates = [newAssociate]

      this.brand.associates = this.brandAssociates
      this.brandVisibility = true
    },
    updateAssociate(associate, updatedAssociate) {
      const associateIndex = this.brandAssociates.indexOf(associate)
      this.brandAssociates[associateIndex].status = updatedAssociate.status
      this.brand.associates = this.brandAssociates
    },
    async showClaimBrandButton() {
      const claimBrandEvent = await events.checkEventLog(this.$store.state.user.current_tenant, 'claimBrand', this.brand.collection)
      if (claimBrandEvent) this.disableClaimBrand = true
    },
    showYesNoDialog() {
      if (this.brandVisibility) {
        this.dialogMessage = swT('set_brand_to_public')
      } else {
        this.dialogMessage = swT('set_brand_to_private')
      }

      this.confirmBrandVisibiltyDialog = true
    },
    handleDialogNo() {
      this.confirmBrandVisibiltyDialog = false
    },
    changeBrandVisibility() {
      this.brandVisibility = !this.brandVisibility
      if (this.brandVisibility) {
        this.brand.associates = []
      } else {
        this.brand.associates = 'public'
        this.brandAssociates = []
      }
      this.confirmBrandVisibiltyDialog = false
    },
    async requestBrandOwnership(brand) {
      try {
        const tenant = this.$store.state.user

        await webServices.sendToOutbox({
          sender: tenant.current_tenant,
          receiver: 'LatestCollection',
          type: 'claimBrand',
          body: {
            brand: brand,
            tenant: tenant,
          },
        })

        this.$store.dispatch('raiseAlert', {
          header: 'request_brand_ownership_sent',
          type: 'success',
          timeout: 3000,
        })

        this.disableClaimBrand = true
      } catch (error) {
        this.$store.dispatch('raiseAlert', {
          header: 'request_brand_ownership_not_sent',
          type: 'error',
          timeout: 3000,
        })
      }
    },

    async getAssociatesInfo() {
      try {
        if (this.brand.associates != undefined && this.brand.associates?.length > 0) {
          return await webServices.getAssociatesWithOwners(this.brand.collection, this.brand.associates)
        }
      } catch (error) {
        return []
      }
    },

    async getTenantsWithOwners() {
      try {
        const getAssociatesWithOwnersResponse = await this.getAssociatesInfo()

        if (getAssociatesWithOwnersResponse?.status === 200) {
          const responseData = getAssociatesWithOwnersResponse.data
          this.brandAssociates = responseData.length > 0 ? responseData : this.brand.associates
        } else {
          this.brandAssociates = this.brand.associates
        }
      } catch (error) {
        this.brandAssociates = this.brand.associates
      }
    },

    checkBrandVisibilty() {
      if (Array.isArray(this.brand.associates)) {
        this.brandVisibility = true
      }
    },
  },
}
</script>

<style>
.warning-text {
  color: #000 !important;
}
</style>
