import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"500"},on:{"input":function($event){return _vm.$emit('input')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline warning white--text"},[_vm._v(" "+_vm._s(_vm.swT('alert'))+" ")]),_c(VCardText,{staticClass:"my-4"},[_c('p',[_vm._v(_vm._s(_vm.message))])]),_c(VCardActions,[(_vm.showRemember)?_c(VCheckbox,{attrs:{"data-test":"rememberCheckBox","large":"","text":"","label":_vm.swT('yes_no_remember')},on:{"click":function($event){return _vm.$emit('remember', _vm.remember)}},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}}):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"data-test":"yesButton","color":"green","large":"","text":""},nativeOn:{"click":function($event){return _vm.$emit('yes')}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.yesKey))+" ")]),_c(VBtn,{attrs:{"data-test":"noButton","color":"red","large":"","text":""},nativeOn:{"click":function($event){return _vm.$emit('no')}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.noKey))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }