import gsap from 'gsap'
function cardStagger() {
  if (gsap.isTweening('.staggerAnimation')) return
  gsap.from('.staggerAnimation', {
    duration: 1,
    opacity: 0,
    scale: 0,
    y: 200,
    ease: 'power1',
    stagger: {
      each: 0.05,
      from: 'center',
      grid: 'auto',
      axis: 'x',
    },
  })
}
function fadeIn() {
  if (gsap.isTweening('.fadeAnimation')) return
  gsap.from('.fadeAnimation', {
    duration: 1.5,
    opacity: 0,
  })
}
function wiggleAnimation() {
  if (gsap.isTweening('.pencil')) return
  gsap.from('.pencil', {
    duration: 1.5,
    x: -150,
    ease: 'bounce',
  })
}
export default {
  cardStagger,
  fadeIn,
  wiggleAnimation,
}
