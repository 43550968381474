import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"500"},on:{"input":function($event){return _vm.$emit('input')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text"},[_vm._v(" "+_vm._s(_vm.swT('info'))+" ")]),_c(VCardText,{staticClass:"my-4"},[_c('p',[_vm._v(_vm._s(_vm.message))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"info","large":"","text":""},nativeOn:{"click":function($event){return _vm.ok.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.swT('ok')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }