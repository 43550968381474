<template>
  <v-tooltip v-bind="tooltipConfig">
    <template v-slot:activator="{ on }">
      <v-btn v-bind="buttonConfig" v-on="on" @click="$emit('swButtonClick', $event)">
        <span v-if="buttonText">{{ buttonText }}</span>
        <v-icon v-if="icon">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['buttonConfig', 'tooltipConfig', 'buttonText', 'icon', 'tooltip'],
}
</script>
