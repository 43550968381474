import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSelect,{attrs:{"data-test":"selectWarehouses","outlined":"","dense":"","small-chips":"","deletable-chips":"","clearable":"","auto-select-first":"","hide-selected":"","multiple":"","return-object":"","items":_vm.warehouseIds},model:{value:(_vm.selectedWarehouses),callback:function ($$v) {_vm.selectedWarehouses=$$v},expression:"selectedWarehouses"}}),(_vm.hasInventory)?_c('swMatrixProduct',{attrs:{"products":_vm.matrix,"readonly":true,"show-eye":true,"hide-zeroes":true,"show-image":false},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var product = ref.product;
return [_c('div',[_c('h2',[_vm._v(_vm._s(product.SKUS[0].articleCodeSupplier))]),_c('h3',[_vm._v(_vm._s(product.SKUS[0].articleDescription))])])]}}],null,false,12698161)}):_c(VCard,[_c(VCardTitle,[_c('h2',[_vm._v(" "+_vm._s(_vm.swT('no_inventory'))+" ")])])],1),(_vm.hasInventory)?_c(VRow,{staticClass:"pt-3"},[_c(VCol,{staticClass:"pr-1",attrs:{"align":"right"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.refresh()}}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-sync")])],1),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.printBarcodes()}}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-barcode")])],1)],1)],1):_vm._e(),_c('swPrintDialog',{attrs:{"print-buffer":_vm.printBuffer},on:{"close-print-dialog":function($event){_vm.printDialog = false},"ok":function($event){_vm.printDialog = false}},model:{value:(_vm.printDialog),callback:function ($$v) {_vm.printDialog=$$v},expression:"printDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }