import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 justify-center"},[(_vm.noProducts)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.swT('noproductsforfilter'))+" ")])]):_vm._e(),_c(VList,[_c(VListItemGroup,{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.products),function(item,i){return [_c(VListItem,{key:("item-" + i),attrs:{"value":item.articleCode,"active-class":"ext--accent-4"},on:{"click":function($event){return _vm.clickRow(item)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VListItemContent,{class:_vm.productClass(item)}),_c(VListItemContent,{staticClass:"px-2"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.articleCodeSupplier)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.articleDescription)}})],1),_c(VListItemContent,{staticClass:"d-none d-sm-block"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.collection)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.brand)}})],1),_c(VListItemContent,{staticClass:"d-none d-sm-block"},[_c(VListItemTitle,[(item.suggestedRetailPrice == undefined || item.suggestedRetailPrice == 0)?_c('swCurrency',{staticClass:"green--text",attrs:{"amount":item.price}}):_c('swCurrency',{staticClass:"red--text strikethrough",attrs:{"amount":item.price}})],1),_c(VListItemSubtitle,[(item.suggestedRetailPrice != 0)?_c('swCurrency',{staticClass:"green--text",attrs:{"amount":item.suggestedRetailPrice}}):_vm._e()],1)],1),_c(VListItemContent,{staticClass:"d-none d-sm-block text-right"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.articleGroup)}}),_c(VListItemSubtitle,[_c('swCurrency',{attrs:{"amount":item.buyPrice}})],1)],1)]},proxy:true}],null,true)})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }