import globalStore from '../store/globalStore'
import startupDB from '../store/client'

const checkEventLog = async function(tenantId: string, eventType: string, brand: string): Promise<boolean> {
  if (!globalStore.getLatestCollectionAPI('eventlog')) globalStore.setLatestCollectionAPI('eventlog', await startupDB({ url: '/' + tenantId + '/eventlog' }))
  const events = globalStore.getLatestCollectionArray('eventlog')
  return events.find((event) => event.payload.brand == brand && event.payload.tenant == tenantId && event.payload.type == eventType)
}

const checkEventLogForInboxActions = async function(senderID: string, receiverID: string, brand: string): Promise<boolean> {
  if (!globalStore.getLatestCollectionAPI('eventlog')) globalStore.setLatestCollectionAPI('eventlog', await startupDB({ url: '/' + senderID + '/eventlog' }))
  const events = globalStore.getLatestCollectionArray('eventlog').reverse()
  return events.find((event) => event.payload.brand == brand && event.payload.tenant == senderID && event.payload.receiver == receiverID)
}

export default {
  checkEventLog,
  checkEventLogForInboxActions,
}
