<template>
  <div v-hotkey="keymap" class="mx-0">
    <v-toolbar v-if="noRouteQuery" class="sticky">
      <swBreadCrumbs :breadcrumbs="breadcrumbs" />
    </v-toolbar>
    <v-toolbar v-if="$route.query.brandId" fixed class="breadcrumbs">
      <v-toolbar-title>
        <span>
          <swBreadCrumbs :breadcrumbs="breadcrumbs" @callback="selectedProduct = null" />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="showPreviousNextProductButton" icon large dark class="primary mx-1" @click="prevProduct">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn v-if="showPreviousNextProductButton" icon large dark class="primary mx-1" @click="nextProduct">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <div v-show="showSpacer" class="drawer__spacer">
        <!-- space to offset the visible drawer -->
      </div>
    </v-toolbar>

    <v-container v-if="whatToShow.brandsGrid">
      <swBrandsGrid :filter="$store.state.filter" :bpopup="$route.query.brandPopUp" />
    </v-container>

    <v-container v-if="whatToShow.productsGrid || whatToShow.productsList">
      <v-toolbar class="sticky" color="grey lighten-4">
        <v-row v-if="loaderDialog" class="justify-center">
          <v-progress-circular :width="3" color="grey darken-1" indeterminate></v-progress-circular>
        </v-row>
        <v-row v-else>
          <swButton icon="mdi-filter" :tooltip="swT('filter')" @click="filterPanel = !filterPanel" />
          <div class="mt-0 mb-8">
            <v-chip class="px-1 red white--text" x-small>
              {{ filteredProducts.length }}
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <swButton class="px-1" data-test="btn-reload" icon="mdi-sync" :tooltip="swT('reload')" @click="refreshDB" />

          <!-- View buttons -->
          <v-btn-toggle v-model="productViewMode" group mandatory>
            <swButton data-test="btn-grid-view" icon="mdi-view-grid" :tooltip="swT('tiles')" />
            <swButton data-test="btn-list-view" icon="mdi-view-list" :tooltip="swT('list')" />
          </v-btn-toggle>
        </v-row>
      </v-toolbar>
      <!-- Current filter vizualization -->
      <v-container class="mt-5 py-0">
        <v-row>
          <v-chip-group v-show="filterPanel" column>
            <v-chip v-for="a in collectionFilter" :key="a" color="green" small :value="a">
              {{ a }}
            </v-chip>
          </v-chip-group>
          <v-chip-group v-show="filterPanel" column>
            <v-chip v-for="a in productGroupFilter" :key="a" color="primary" small :value="a">
              {{ a }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-container>
      <!-- Filter controls -->
      <v-container v-show="filterPanel" class="pt-0">
        <v-row>
          <v-col cols="12" sm="5">
            <v-combobox v-model="collectionFilter" outlined hide-details :items="brandCollections" :label="swT('collection')" multiple small-chips deletable-chips></v-combobox>
          </v-col>
          <v-col v-show="$vuetify.breakpoint.smAndUp" cols="0" sm="2" align="center">
            <v-btn large icon @click="showAllAttributes = !showAllAttributes">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="!showAllAttributes" v-on="on">
                    mdi-dots-horizontal
                  </v-icon>
                  <v-icon v-else v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <span v-if="!showAllAttributes">{{ swT('more...') }}</span>
                <span v-else>{{ swT('less...') }}</span>
              </v-tooltip>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="5">
            <v-combobox
              v-model="productGroupFilter"
              outlined
              hide-details
              :items="brandProductGroups"
              :label="swT('productgroup')"
              multiple
              small-chips
              deletable-chips
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="showAllAttributes">
          <v-col v-for="(value, attributeName) in attributes" :key="attributeName" cols="12" sm="6" md="4" lg="3">
            <v-combobox v-model="attributeFilters[attributeName]" outlined hide-details :items="value" :label="attributeName" multiple small-chips deletable-chips></v-combobox>
          </v-col>
        </v-row>
      </v-container>

      <swProductsGrid v-if="whatToShow.productsGrid" :products="filteredProducts.slice(0, 100)" :loader-dialog="loaderDialog" @update:selected="selectProduct($event)" />

      <swProductsList
        v-if="whatToShow.productsList"
        :products="filteredProducts.slice(0, 100)"
        :brand-id="$route.query.brandId"
        :selected-products="selectedProducts"
        :loader-dialog="loaderDialog"
        @update:selected="selectProduct($event)"
        @update:multiSelected="selectedProducts = $event"
      />
    </v-container>
    <swProductForm
      v-if="whatToShow.productForm && selectedProduct"
      class="mt-0"
      :product="selectedProduct"
      :brand-products="brandProducts"
      :brand="$route.query.brandId"
      @save="saveProduct($event)"
    />

    <swScrollTopFab />
    <swInfoDialog v-model="notFoundDialog" :message="swT('productnotfound')" route-on-ok="products" @close-info-dialog="notFoundDialog = false" />
    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ swT('standby') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { swT } from '@/functions/i18n'
import swScrollTopFab from '../components/swScrollTopFab.vue'
import swBreadCrumbs from '../components/swBreadCrumbs.vue'
import swInfoDialog from '../components/swInfoDialog.vue'
import swProductForm from '../components/swProductForm.vue'
import swBrandsGrid from '../components/swBrandsGrid.vue'
import swProductsGrid from '../components/swProductsGrid.vue'
import swProductsList from '../components/swProductsList.vue'
import swButton from '../components/swButton.vue'
import globalStore from '../store/globalStore'
import productFunctions from '../functions/products'
import userFunctions from '../functions/users'
import tools from '../functions/tools'
import consts from '../store/consts'
import { eventBus } from '../main'
import { findSkuByBarcode, hashBrand } from '@softwear/latestcollectioncore'

export default {
  components: {
    swScrollTopFab,
    swBreadCrumbs,
    swProductForm,
    swBrandsGrid,
    swProductsGrid,
    swProductsList,
    swInfoDialog,
    swButton,
  },
  props: ['query'],
  data() {
    return {
      swT,
      attributesPanel: 1,
      attributeFilters: {},
      brandFilter: '',
      brandProducts: [],
      collectionFilter: [],
      fab: false,
      filterPanel: true,
      loaderDialog: false,
      notFoundDialog: false,
      ping: 0,
      productDialog: false,
      productGroupFilter: [],
      productViewMode: 0,
      selectedProduct: null,
      selectedProducts: [],
      inventoryManagementUpdate: {},
      showAllAttributes: false,
      whatToShow: {
        brandsGrid: false,
        productsGrid: false,
        productsList: false,
        productForm: false,
      },
    }
  },
  computed: {
    breadcrumbs() {
      const crumb1 = {
        text: swT('products'),
        disabled: false,
        to: '/products',
        callback: () => this.chooseView('brandsGrid'),
      }
      const brandId = hashBrand(this.query?.brandId)
      const brand = this.$store.state.brands.find((brand) => brand.collection == brandId)

      const dataProvider = ['', undefined].includes(this.query?.dataProvider) ? '' : this.query.dataProvider + '/'
      const brandText = brand ? dataProvider + brand?.name : '' || brandId

      const link = `/products?brandId=${brandId}`
      if (this.query?.feature) link.concat(`&feature=${this.query.feature}`)
      if (this.query?.dataProvider) link.concat(`&dataProvider=${this.query.dataProvider}`)

      const crumb2 = {
        text: brandText,
        disabled: !this.selectedProduct,
        to: link,
        callback: () => this.chooseView('productsGrid'),
      }
      if (!this.selectedProduct) return [crumb1, crumb2].filter((crumb) => crumb.text.length)

      const crumb3 = {
        text: this.selectedProduct.articleCodeSupplier || this.selectedProduct.articleCode,
        disabled: true,
        to: '',
      }
      return [crumb1, crumb2, crumb3].filter((crumb) => crumb.text && crumb.text.length)
    },
    noRouteQuery() {
      return Object.keys(this.query || {}).length === 0
    },
    keymap() {
      return {
        'ctrl+<': this.prevProduct,
        'ctrl+>': this.nextProduct,
      }
    },
    attributes() {
      return tools.pickByKey(this.$store.state.attributes, this.$store.state.activeConfig.products.activeAttributes.value) || {}
    },
    hasProductRole() {
      const tenant = this.$store.state.user.current_tenant
      const roles = this.$store.state.user.roles[tenant]
      return userFunctions.hasRole(roles, 'products')
    },
    brand() {
      return hashBrand(this.query.brandId)
    },
    skuEditorSkus() {
      return productFunctions.getBrandSkus(this.brand)
    },
    showPreviousNextProductButton() {
      return !this.$store.state.editing && this.whatToShow.productForm && this.selectedProduct
    },
    showSpacer() {
      return this.$vuetify.breakpoint.lgAndUp && this.$store.state.drawer
    },
    brandProductGroups() {
      return tools.getUniqueValues(this.brandProducts.map((e) => e.articleGroup)).sort()
    },
    brandCollections() {
      return tools
        .getUniqueValues(this.brandProducts.map((e) => e.collection))
        .sort()
        .reverse()
    },
    filteredProducts() {
      // We can filter by:
      //
      // Barcode: when a 12/13 digit number is used as filter, a barcode lookup is performed.
      //
      // ArticleCode: a list of strings typed by the user. Matches when one of those stings is found as part of an articleCode
      // Groups: a list of values for articleGroup and collection selected by the user
      //
      if ((this.$store.state.filter?.length == 12 || this.$store.state.filter?.length == 13) && !isNaN(this.$store.state.filter)) {
        return []
      }
      // Make a filters array with all comma separated filer values typed by the user
      const filters = this.$store.state.filter ? this.$store.state.filter.toLowerCase().split(',') : []
      // Remove possible empty filter when user ends his quest by a ,
      if (!filters[filters.length - 1]) filters.pop()
      return this.brandProducts.filter((product) => {
        // Filter out inactive products
        if (!product.active) return false
        // Perform case insensitive searches
        const articleCode = product.articleCode?.toLowerCase() || ''
        const articleDescription = product.articleDescription?.toLowerCase() || ''

        // If there are no filter values, we are GO to include this item in the search
        // Otherwise set searchStatus to NO GO until we find a match on one of the filtervalues
        let searchStatus = filters.length > 0 ? 'NO GO' : 'GO'
        for (const f of filters)
          if (articleCode.includes(f) || articleDescription.includes(f)) {
            searchStatus = 'GO'
            continue
          }

        if (filters && searchStatus == 'NO GO') return false
        if (this.productGroupFilter.length > 0 && this.productGroupFilter.indexOf(product.articleGroup) == -1) return false
        if (this.collectionFilter.length > 0 && this.collectionFilter.indexOf(product.collection) == -1) return false
        for (const attributeFilterName in this.attributeFilters) {
          if (this.attributeFilters[attributeFilterName].length > 0 && this.attributeFilters[attributeFilterName].indexOf(product['_' + attributeFilterName]) == -1) return false
        }
        return true
      })
    },
  },
  watch: {
    async '$store.state.scannedBarcode'(newValue) {
      // Vue will fire this watch on all components cached by <keep-alive>.
      // We only need to repsond on the active component
      if (this._inactive == true) return

      if (this.$store.state.editing) return

      // If not a valid barcode
      if (!newValue || (newValue.length != 13 && newValue.length != 12) || isNaN(newValue) || newValue.indexOf(',') != -1) {
        this.$store.dispatch('clearFilter')
        // Show alert only if there is a value in newValue to prevent showing the alert when getting the field empty
        if (newValue)
          this.$store.dispatch('raiseAlert', {
            header: 'incorrect_barcode',
            type: 'warning',
            timeout: 3000,
          })
        return
      }

      // Perform barcode search
      // First, look for this barcode in the tenantSkus
      const tenantSkus = globalStore.getLatestCollectionObject('sku')
      const barcode = newValue
      const tenantSku = findSkuByBarcode(tenantSkus, barcode)

      // 8718218298776
      if (tenantSku) return this.redirectToProductView(tenantSku.brand, barcode)

      // So it was not a barcode in the tenant data...
      // Ask the server
      this.$store.dispatch('getBarcode', {
        barcode: barcode,
        callback: this.procBarcode,
      })
    },
    // When a barcode appears in the filter box switch to barcode scan mode
    '$store.state.filter'(newValue) {
      // Vue will fire this watch on all components cached by <keep-alive>.
      // We only need to repsond on the active component
      if (this._inactive == true) return

      if (this.$store.state.editing) return

      // If the value is a barcode value, switch to barcode mode
      if (newValue && (newValue.length == 13 || newValue.length == 12) && !isNaN(newValue) && newValue.indexOf(',') == -1) {
        this.$store.state.productPickerActive = true
        this.$store.state.barcodeScannerMode = true
        this.$store.state.scannedBarcode = newValue
      }
    },
    productViewMode(newValue) {
      newValue ? this.chooseView('productsList') : this.chooseView('productsGrid')
    },
    query: {
      handler() {
        if (this.noRouteQuery) return this.chooseView('brandsGrid')
        if (this.query.productId || this.query.ean) this.findAndShowProduct()
        if (this.query.brandId) this.prepareBrandProducts()
        if (this.query.brandPopUp) this.chooseView('brandsGrid')
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.$store.dispatch('clearFilter')
    this.$store.state.mainFilterPrompt = swT('findproductorbarcode')
    if (this.whatToShow.productsGrid || this.whatToShow.productsList) this.$store.state.mainFilterPrompt = swT('find_product_articlecode')
  },
  deactivated() {
    if (this.$route.path === '/products') return
    this.$store.state.productPickerActive = false
    this.$store.state.barcodeScannerMode = false
  },
  async created() {
    eventBus['$on']('inventoryManagementUpdate', this.updateInventoryManagement)
    eventBus['$on']('save', this.saveProduct)
    eventBus['$on']('cancel', this.cancel)

    this.consts = consts // Add non-reactive consts
    this.selectedProduct = null
    this.$store.dispatch('clearFilter')
  },
  destroyed() {
    eventBus['$off']('inventoryManagementUpdate', this.updateInventoryManagement)
  },
  methods: {
    chooseView(view) {
      this.whatToShow = {
        brandsGrid: false,
        productsGrid: false,
        productsList: false,
        productForm: false,
      }
      this.whatToShow[view] = true
    },
    selectProduct(product) {
      if (product?.articleCode) this.selectedProduct = productFunctions.getProductDetails(product)
      else this.selectedProduct = product
      this.chooseView('productForm')
    },
    async goToSkuEdit() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) {
        await this.$store.dispatch('loadBrand', { brand: this.query.brandId, dataProvider: '' })
      }
      if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) {
        await this.$store.dispatch('loadBrand', { brand: this.query.brandId, dataProvider: this.$store.state.user.latestCollectionSettings.dataProviderAdmin })
      }
      this.$router.push({ path: 'skueditor', query: { brand: this.query.brandId } })
    },
    updateInventoryManagement({ warehouseId, barcode, property, qty }) {
      this.$store.dispatch('setEditing', true)
      this.$store.state.activeFormValid = true
      if (!this.inventoryManagementUpdate[barcode]) this.inventoryManagementUpdate[barcode] = { data: {} }
      if (!this.inventoryManagementUpdate[barcode].data[warehouseId]) this.inventoryManagementUpdate[barcode].data[warehouseId] = {}
      this.inventoryManagementUpdate[barcode].data[warehouseId][property] = qty || 0
    },
    async refreshProducts(force) {
      // Ideally, the next lines should have been computed properties but on
      // 250.000 skus, that's too slow
      // So here we do the 'reactivity' manually
      let skus = []
      this.loaderDialog = true
      if (force) globalStore.resetLatestCollectionAPI('selectedbrand')

      const cachedBrand = (await globalStore?.getLatestCollectionAPI('selectedbrand')?.brand) || ''
      const newData = await productFunctions.setBrandContext(this.$store, this.query.brandId, this.query.dataProvider, cachedBrand)
      skus = globalStore.getBrandSkus()
      if (newData || force) {
        globalStore.setIndexedBrandProducts(productFunctions.buildProductIndexedObject(skus))
        this.brandProducts = productFunctions.getBrandProducts(skus, this.$store.getters.sizeOrdering, this.$store.getters.mapSubSizes)
      } else {
        // skus = globalStore.getBrandSkus()
        this.brandProducts = globalStore.getBrandProducts()
      }
      this.loaderDialog = false
    },
    async refreshDB() {
      await this.$store.dispatch('refreshDB')
      await this.refreshProducts()
    },
    cancel() {
      this.$store.dispatch('setEditing', false)
    },
    async saveProduct() {
      // Send updated inventoryManagement parameters to server
      if (this.inventoryManagementUpdate) {
        await this.$store.dispatch('updateObjects', {
          api: globalStore.getLatestCollectionAPI('inventorymanagement'),
          data: Object.keys(this.inventoryManagementUpdate).map((key) => {
            return { id: key, ...this.inventoryManagementUpdate[key] }
          }),
          auditHeaders: {
            'x-transaction-audit-source': 'Save product',
          },
        })
      }
      this.$store.dispatch('setEditing', false)
    },
    prevProduct() {
      if (this.$store.state.editing) return
      if (!this.selectedProduct) return
      // Find previous product within filter and set this.selectedProduct accordingly
      for (const i in this.filteredProducts) {
        if (this.filteredProducts[i].articleCode == this.selectedProduct.articleCode) {
          let j = 1
          while (j <= i) {
            let nextI = ''
            nextI = parseInt(i) - j + ''
            if (this.filteredProducts[nextI] && this.filteredProducts[nextI].usedByTenant) {
              this.selectedProduct = productFunctions.getProductDetails(this.filteredProducts[nextI])
              return
            }
            j++
          }
        }
      }
      this.chooseView('productsGrid')
    },
    nextProduct() {
      if (this.$store.state.editing) return
      if (!this.selectedProduct) return
      // Find next product within filter and set this.selectedProduct accordingly
      for (const i in this.filteredProducts) {
        if (this.filteredProducts[i].articleCode == this.selectedProduct.articleCode) {
          let j = 1
          while (j < this.filteredProducts.length) {
            let nextI = ''
            nextI = parseInt(i) + j + ''
            if (this.filteredProducts[nextI] && this.filteredProducts[nextI].usedByTenant) {
              this.selectedProduct = productFunctions.getProductDetails(this.filteredProducts[nextI])
              return
            }
            j++
          }
        }
      }
      this.chooseView('productsGrid')
    },
    async prepareBrandProducts() {
      await this.$store.state.skuPromise
      await this.refreshProducts(true)
      // Everything is now ready for the Brands view but if a productId is given, this serves only as context for the productForm...
      if (this.query.productId) {
        this.selectedProduct = {}
        let productFound = false
        for (const product of this.brandProducts) {
          // Find product in brand products
          // First check for a match on articleCode
          if (product.articleCode == this.query.productId) {
            this.selectedProduct = product
            productFound = true
            break
          }
          // Now check for a match on barcode
          if (this.query.productId.length == 12 || this.query.productId.length == 13) {
            product.SKUS.forEach((sku) => {
              if (sku.barcode == this.query.productId) {
                this.selectedProduct = product
                productFound = true
              }
            })
          }
          if (productFound) break
        }
        if (!productFound) return (this.notFoundDialog = true)
        // Prepare the Product view
        // Our product is constructed from non-reactive data. Make a reactive copy.
        this.selectedProduct = productFunctions.getProductDetails(this.selectedProduct)
        this.chooseView('productForm')
      } else {
        this.chooseView('productsGrid')
      }
    },
    redirectToProductView(brandId, productId, dataProvider) {
      this.$router
        .push({
          path: 'products',
          query: {
            brandId: brandId,
            productId: productId,
            dataProvider: dataProvider,
          },
        })
        .catch((err) => err)
      this.chooseView('productForm')
    },
    async findAndShowProduct() {
      await this.$store.state.skuPromise
      for (const p of globalStore.getLatestCollectionArray('sku')) {
        if (p.articleCode == this.query.productId) return this.redirectToProductView(p.brand, p.articleCode)
        if (p.id == this.query.productId) return this.redirectToProductView(p.brand, p.id)
        if (p.id == this.query.ean) return this.redirectToProductView(p.brand, p.id)
      }
    },
    procBarcode(barcode) {
      if (!barcode) {
        this.$store.dispatch('clearFilter')
        return this.$store.dispatch('raiseAlert', {
          header: 'unknownbarcode',
          type: 'warning',
          timeout: 3000,
        })
      }
      this.redirectToProductView(barcode.brand, barcode.id, barcode.dataProvider)
    },
  },
}
</script>

<style lang="scss" scoped>
.products {
  &__toolbar {
    &--main {
      top: 64px;
      position: sticky;
      width: 100%;
      z-index: 3;
    }
  }
  &__spacer {
    width: 256px;
  }
}
</style>
