<template>
  <div>
    <v-tabs v-model="tabIndex" show-arrows grow class="sticky mb-2" dark background-color="info" active-class="text-h5" hide-slider>
      <v-tab data-test="productOverview">{{ swT('productOverview') }}</v-tab>
      <v-tab data-test="barcodeTab">{{ swT('barcodes') }}</v-tab>
      <v-tab data-test="inventoryTab">{{ swT('inventory') }}</v-tab>
      <v-tab data-test="inventoryManagementTab">{{ swT('reorder') }}</v-tab>
      <v-tab data-test="manualLabelTab">
        {{ swT('manual_labels') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabIndex" class="px-2">
      <v-tab-item>
        <keep-alive>
          <v-container v-hotkey="keymap">
            <v-card>
              <v-card-title class="headline primary white--text py-2">
                <v-row>
                  <v-col cols="8" class="d-flex align-center">{{ selectedProduct.articleCodeSupplier }}</v-col>
                  <v-col cols="4" class="text-right">
                    <swTooltipButton
                      data-test="showImages"
                      :button-config="{
                        disabled: !readonly,
                        fab: true,
                        color: $store.state.activeConfig.products.showimagesonproductform.value ? 'gray' : 'green',
                        dark: true,
                        small: true,
                        class: 'mx-2',
                      }"
                      :icon="'mdi-eye'"
                      :tooltip="swT('show_images')"
                      :tooltip-config="{ bottom: true }"
                      @swButtonClick="$store.state.activeConfig.products.showimagesonproductform.value = !$store.state.activeConfig.products.showimagesonproductform.value"
                    />
                    <v-tooltip v-if="images(product.SKUS)" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="!$vuetify.breakpoint.xs"
                          data-test="btn-download-multiple"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="green"
                          v-on="on"
                          @click="downloadImages(product.SKUS)"
                        >
                          <v-icon v-if="!downloadingImages" dark>mdi-download-multiple</v-icon>
                          <v-progress-circular v-else :width="3" :size="25" color="white" indeterminate></v-progress-circular>
                        </v-btn>
                      </template>
                      <span>{{ swT('downloadImages') }}</span>
                    </v-tooltip>
                    <swAuth v-if="product.pricat" role="sw">
                      <v-btn data-test="openPricat" class="mx-2" fab dark small color="blue" @click="openPricat(product.pricat)">
                        <v-icon dark>mdi-matrix</v-icon>
                      </v-btn>
                    </swAuth>
                    <swAuth role="products-admin,products-user">
                      <v-btn
                        v-if="selectedProduct.usedByTenant || selectedProduct.source === 'tenant'"
                        data-test="editProduct"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        :disabled="!readonly"
                        @click="goToSkuEditor"
                      >
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                      <swTooltipButton
                        v-else
                        data-test="downloadProduct"
                        :button-config="{ disabled: !readonly, fab: true, color: 'green', dark: true, small: true, class: 'mx-2' }"
                        :icon="'mdi-download'"
                        :tooltip="swT('downloadProduct')"
                        :tooltip-config="{ bottom: true }"
                        @swButtonClick="saveProductToTenantDb"
                      />
                    </swAuth>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-slide-group v-if="$store.state.activeConfig.products.showimagesonproductform.value" v-model="slideGroupModel" class="imageSlider" center-active show-arrows>
                <v-slide-item v-for="(img, i) in selectedProduct.IMAGES" :key="i" v-slot="{ active, toggle }">
                  <v-card text class="ma-2 d-flex align-center" max-width="150px" height="fit-content" color="transparent" @click="toggle">
                    <v-scale-transition>
                      <v-img v-if="img.url.slice(0, 4) != 'blob'" :src="imageSrc(img.url, 150)" contain></v-img>
                      <v-img v-else :src="imageSrc(img.url, 150)" contain></v-img>
                    </v-scale-transition>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-card>
            <v-form ref="form">
              <v-card class="my-2">
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        ref="articleCodeSupplier"
                        v-model="selectedProduct.articleCodeSupplier"
                        data-test="articleCodeSupplierField"
                        dense
                        outlined
                        hide-details
                        readonly
                        :label="swT('productcodesupplier')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        v-model="selectedProduct.articleDescription"
                        data-test="articleDescriptionField"
                        dense
                        outlined
                        hide-details
                        readonly
                        :label="swT('productdescription')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3"></v-col>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        v-model="selectedProduct.articleCode"
                        data-test="articleCodeField"
                        dense
                        outlined
                        hide-details
                        readonly
                        :label="swT('productid')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="selectedProduct.collection" data-test="collectionField" dense outlined hide-details readonly :label="swT('collection')"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="selectedProduct.articleGroup"
                        data-test="articleGroupField"
                        dense
                        outlined
                        hide-details
                        readonly
                        :label="swT('productgroup')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card v-if="$store.state.activeConfig.salesChannels.mySalesChannels.value.includes('wholesale')" class="my-2 green lighten-5">
                <v-card-text>
                  <v-row>
                    <p class="caption">--{{ swT('wholesale') }}--</p>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <v-currency-field
                          v-model="selectedProduct.productionPrice"
                          class="right-input"
                          data-test="productionPriceField"
                          outlined
                          hide-details
                          readonly
                          dense
                          prefix="€"
                          :label="swT('productionprice')"
                        ></v-currency-field>
                      </v-col>
                      <v-col cols="6" sm="2">
                        <v-currency-field
                          v-model="selectedProduct.costPrice"
                          class="right-input"
                          data-test="costPriceField"
                          outlined
                          hide-details
                          readonly
                          dense
                          prefix="€"
                          :label="swT('costprice')"
                        ></v-currency-field>
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-text-field
                          v-model="wholesaleCalculation"
                          data-test="wholesaleCalculationField"
                          outlined
                          hide-details
                          readonly
                          dense
                          type="number"
                          min="0.00"
                          step="0.1"
                          :label="swT('calculation')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="1">
                        <!-- @focus="$refs.price.focus()" -->
                        <v-text-field v-model="wholesaleMargin" outlined readonly hide-details dense :label="swT('margin')"></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="2">
                        <v-currency-field
                          ref="price"
                          v-model="selectedProduct.wholesalePrice"
                          class="right-input"
                          data-test="wholesalePriceField"
                          outlined
                          hide-details
                          readonly
                          dense
                          prefix="€"
                          :label="swT('wholesaleprice')"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="6" sm="2">
                        <v-currency-field
                          v-model="selectedProduct.landedCostPrice"
                          class="right-input"
                          data-test="salePriceField"
                          outlined
                          hide-details
                          readonly
                          dense
                          prefix="€"
                          :label="swT('landedcostprice')"
                        ></v-currency-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" sm="2">
                        <v-currency-field
                          v-model="selectedProduct.suggestedRetailPrice"
                          class="right-input"
                          data-test="salePriceField"
                          outlined
                          hide-details
                          readonly
                          dense
                          prefix="€"
                          :label="swT('suggestedretailprice')"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="my-2 light-blue lighten-5">
                <v-card-text>
                  <!-- Only show next caption in case there also is a wholesale card -->

                  <p v-if="$store.state.activeConfig.salesChannels.mySalesChannels.value.includes('wholesale')" class="caption">-- {{ swT('retail') }} --</p>
                  <v-row>
                    <v-col cols="6" sm="2">
                      <v-currency-field
                        v-model="selectedProduct.buyPrice"
                        class="right-input"
                        data-test="buyPriceField"
                        outlined
                        hide-details
                        readonly
                        dense
                        prefix="€"
                        :label="swT('buyprice')"
                      ></v-currency-field>
                    </v-col>
                    <v-col cols="6" sm="2" class="d-flex">
                      <v-text-field
                        v-model="retailCalculation"
                        data-test="calculationField"
                        outlined
                        hide-details
                        readonly
                        dense
                        type="number"
                        min="0.00"
                        step="0.1"
                        :label="swT('calculation')"
                      ></v-text-field>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon class="pl-1" v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        {{ swT('calculationTooltip') }}
                      </v-tooltip>
                    </v-col>
                    <v-col cols="6" sm="2" class="d-flex">
                      <v-text-field v-model="retailMargin" data-test="retailMarginField" outlined readonly hide-details dense :label="swT('margin')"></v-text-field>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon class="pl-1" v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        {{ swT('marginTooltip') }}
                      </v-tooltip>
                    </v-col>
                    <v-col cols="6" sm="2">
                      <v-currency-field
                        ref="price"
                        v-model="selectedProduct.price"
                        class="right-input"
                        data-test="sellPriceField"
                        outlined
                        hide-details
                        readonly
                        dense
                        prefix="€"
                        :label="swT('sellprice')"
                      ></v-currency-field>
                    </v-col>
                    <v-col cols="6" sm="2">
                      <v-currency-field
                        v-model="selectedProduct.salePrice"
                        class="right-input"
                        data-test="salePriceField"
                        outlined
                        hide-details
                        readonly
                        dense
                        prefix="€"
                        :label="swT('saleprice')"
                      ></v-currency-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-container>
        </keep-alive>
      </v-tab-item>
      <v-tab-item>
        <keep-alive>
          <swSkuEditor :skus="product.SKUS" :readonly="true" />
        </keep-alive>
      </v-tab-item>
      <v-tab-item>
        <swProductFormInventoryTab :product="product" />
      </v-tab-item>
      <v-tab-item>
        <swProductFormInventoryManagementTab :product="product" />
      </v-tab-item>
      <v-tab-item>
        <swProductFormManualLabelTab :product="product" />
      </v-tab-item>
      <v-tab-item>
        <swProductFormHistoryTab :product="product" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import tools from '../functions/tools'
import webServices from '../functions/webServicesFacade'
import swTooltipButton from '../components/swTooltipButton.vue'
import productFunctions from '../functions/products'
import swProductFormInventoryTab from '../components/swProductFormInventoryTab.vue'
import swProductFormInventoryManagementTab from '../components/swProductFormInventoryManagementTab.vue'
import swProductFormManualLabelTab from '../components/swProductFormManualLabelTab.vue'
import swProductFormHistoryTab from '../components/swProductFormHistoryTab.vue'
import swSkuEditor from '../components/swSkuEditor.vue'

export default {
  components: {
    swTooltipButton,
    swProductFormInventoryTab,
    swProductFormInventoryManagementTab,
    swProductFormManualLabelTab,
    swProductFormHistoryTab,
    swSkuEditor,
  },
  props: ['value', 'product', 'brand', 'brandProducts'],
  data() {
    return {
      swT,
      slideGroupModel: 0,
      controlPanels: [],
      valid: false,
      skusPanel: 0,
      wholesaleCalculation: 1,
      retailCalculation: 1,
      selectedProduct: {},
      tabIndex: 0,
      downloadingImages: false,
    }
  },
  computed: {
    globalColors() {
      return this.$store.state.colors
    },
    readonly() {
      return !this.$store.state.editing
    },
    keymap() {
      return {
        'ctrl+0': this.clearPanels,
        'ctrl+1': this.togglePanel,
        'ctrl+e': this.goToSkuEditor,
      }
    },
    wholesaleMargin() {
      if (this.selectedProduct.wholesalePrice == 0) return ''
      return (((this.selectedProduct.wholesalePrice - this.selectedProduct.landedCostPrice) / this.selectedProduct.wholesalePrice) * 100).toFixed(1) + '%'
    },
    retailMargin() {
      return productFunctions.calculateMargin(this.selectedProduct, this.$store.state.vatHi)
    },
  },
  watch: {
    product: {
      handler() {
        if (this.product) {
          this.reset()
        }
      },
      deep: false,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('clearFilter')
    if (this.$store.state.activeConfig.products.showimagesonproductform.value) this.controlPanels.push(0)
  },
  activated() {
    this.$store.dispatch('clearFilter')
    this.$vuetify.goTo(0)
  },
  methods: {
    async saveProductToTenantDb() {
      this.selectedProduct.SKUS.forEach((sku) => {
        if (sku.articleGroup && !sku.articleGroupSupplier) sku.articleGroupSupplier = sku.articleGroup
        if (sku.size && !sku.sizeSupplier) sku.sizeSupplier = sku.size
        if (sku.collection && !sku.collectionSupplier) sku.collectionSupplier = sku.collection
        delete sku.articleGroup
        delete sku.size
        delete sku.collection
      })
      await this.$store.dispatch('updateSkuObjects', { data: this.selectedProduct.SKUS, audit: 'Product form' })
      this.$forceUpdate()
    },
    goToSkuEditor() {
      this.$router.push({
        path: 'skueditor',
        query: { filter: `articleCodeSupplier=="${this.selectedProduct.articleCodeSupplier}"`, brand: this.selectedProduct.brand, from: 'skuEditor' },
      })
    },
    togglePanel(e) {
      if (!e.code.includes('Digit')) return
      const val = parseInt(e.code.substring(5)) - 1
      if (!this.controlPanels.includes(val)) this.controlPanels.push(val)
      else this.controlPanels = this.controlPanels.filter((e) => e != val)
    },
    clearPanels() {
      this.controlPanels = []
    },
    async downloadImages(skus) {
      const imageList = []
      skus
        .filter((sku) => sku.images)
        .forEach((sku) => {
          const images = sku.images.split(',')
          for (const image of images) {
            if (!imageList.includes(image)) imageList.push(image)
          }
        })
      if (imageList == undefined || imageList.length == 0) {
        this.$store.dispatch('raiseAlert', {
          header: 'noAvailableImages',
          type: 'warning',
          timeout: 5000,
        })
        return
      }
      const allImages = imageList.length
      this.downloadingImages = true
      for (let index = 0; index < allImages; index++) {
        const image = imageList[index]
        const success = await webServices.forceDownload(`https://${image}`, image.substring(image.lastIndexOf('/') + 1, image.length))
        if (allImages - 1 === index || !success) this.downloadingImages = false
      }
    },
    openPricat(pricat) {
      window.open('https://www.softwear.nl/pricats/' + pricat, '_blank')
    },
    reset() {
      this.selectedProduct = this.product
      this.$forceUpdate()
      this.$vuetify.goTo(0)
      this.wholesaleCalculation = this.product.costPrice ? (this.product.wholesalePrice / this.product.costPrice).toFixed(1) : this.product.calculation
      this.retailCalculation = this.product.buyPrice ? (this.product.price / this.product.buyPrice).toFixed(1) : this.product.calculation
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
    images(skus) {
      return skus?.some((sku) => sku.images)
    },
  },
}
</script>
<style scoped>
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.3);
}
.v-snack__wrapper {
  min-width: 220px;
}
.controlSnack {
  bottom: 8px;
  margin: auto;
}
.swProductForm__product--img {
  max-height: 200px;
  width: 150px;
}
.right-input >>> input {
  text-align: right;
}
.imageSlider >>> .v-slide-group__content {
  align-items: center;
}
</style>
