const beddinghouseSettings = [
  { type: 'text', field: 'client', label: 'client' },
  { type: 'text', field: 'clientdelivery', label: 'clientdelivery' },
  { type: 'text', field: 'OcpApimSubscriptionKey', label: 'Ocp-Apim-Subscription-Key' },
  // { type: 'alert', header: 'configure_gln_in_warehouse', color: 'info', body: 'api_expects_gln_configured_for_warehouse' },
]
const smellinkSettings = [{ type: 'text', field: 'SmellinkApiToken', label: 'SmellinkApiToken' }]
const arliSettings = [{ type: 'text', field: 'ArliCardCode', label: 'ArliCardCode' }]
const itsPerfectSettings = [
  { type: 'text', field: 'apiRootUrl', label: 'apiRootUrl' },
  { type: 'text', field: 'apiToken', label: 'apiToken' },
  { type: 'text', field: 'apiUser', label: 'apiUser' },
  { type: 'text', field: 'apiPwd', label: 'apiPwd' },
  { type: 'text', field: 'invoiceTo', label: 'invoiceTo' },
]

export default {
  beddinghouse: beddinghouseSettings,
  beddinghousecare: beddinghouseSettings,
  athomebybeddinghouse: beddinghouseSettings,
  beddinghousedutchdesign: beddinghouseSettings,
  beddinghousexfiepamsterdam: beddinghouseSettings,
  beddinghousexhannekedejager: beddinghouseSettings,
  beddinghousekids: beddinghouseSettings,
  beddinghousexvangoghmuseum: beddinghouseSettings,
  pipstudio: beddinghouseSettings,
  oilily: beddinghouseSettings,
  rivieramaison: beddinghouseSettings,
  ariadneathome: beddinghouseSettings,
  kaatamsterdam: beddinghouseSettings,
  vtwonen: beddinghouseSettings,
  kardol: beddinghouseSettings,
  marjoleinbastin: beddinghouseSettings,
  smellink: smellinkSettings,
  livello: smellinkSettings,
  linolux: smellinkSettings,
  mrmrsamsterdambylivello: smellinkSettings,
  bonnanotte: smellinkSettings,
  dreamstar: smellinkSettings,
  polardream: smellinkSettings,
  gilder: smellinkSettings,
  cevilit: smellinkSettings,
  ddddd: arliSettings,
  cinderella: arliSettings,
  damai: arliSettings,
  seahorse: arliSettings,
  summerset: arliSettings,
  vandyck: itsPerfectSettings,
}
