import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"500"},on:{"input":function($event){return _vm.$emit('input')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text"},[_vm._v(" "+_vm._s(_vm.swT('print-jobs'))+" ")]),_c(VCardText,{staticClass:"my-8"},[_vm._l((_vm.jobs),function(job){return _c(VRow,{key:job},[_c(VCol,[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"info","dark":"","data-test":("print-btn-" + job)},nativeOn:{"click":function($event){return _vm.print(job)}}},[_vm._v(" "+_vm._s(job)+" ")])],1)],1)}),(_vm.campaigns.length > 0)?_c(VRow,[_c(VCol,[_c(VSelect,{staticClass:"mt-2",attrs:{"data-test":"selectCampaign","dense":"","outlined":"","hide-details":"","items":_vm.campaigns,"label":_vm.swT('applyCampaign')},model:{value:(_vm.selectedCampaign),callback:function ($$v) {_vm.selectedCampaign=$$v},expression:"selectedCampaign"}})],1)],1):_vm._e(),_c(VRow,[_c(VCol,[_c(VSelect,{staticClass:"mt-2",attrs:{"data-test":"selectPrinter","dense":"","outlined":"","hide-details":"","items":_vm.devices,"label":_vm.swT('select_printer')},model:{value:(_vm.selectedDevice),callback:function ($$v) {_vm.selectedDevice=$$v},expression:"selectedDevice"}})],1)],1)],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"data-test":"okButton","color":"info","large":"","text":""},nativeOn:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(" "+_vm._s(_vm.swT('close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }