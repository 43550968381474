<template>
  <v-dialog :value="value" max-width="500" @input="$emit('input')">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ swT('xls-import') }}
      </v-card-title>
      <v-card-text>
        <v-card class="ma-2" color="green lighten-3">
          <v-card-text>
            {{ swT('xls_import_only_new') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="onyNewButton" large @click.native="$emit('operation', 'new_only')">
              {{ swT('new_only') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" color="warning lighten-3">
          <v-card-text>
            {{ swT('xls_import_only_existing') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="onyExistingButton" large @click.native="$emit('operation', 'update_only')">
              {{ swT('update_only') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" color="error lighten-3">
          <v-card-text>
            {{ swT('xls_import_all') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="allButton" large @click.native="$emit('operation', 'all')">
              {{ swT('all') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-test="cancelButton" color="info" large text @click.native="$emit('operation', 'cancel')">
            {{ swT('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
export default {
  props: ['value'],
  data() {
    return {
      swT,
    }
  },
  deactivated() {
    this.$destroy()
  },
}
</script>
