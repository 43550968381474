<template>
  <div>
    <v-select
      v-model="selectedWarehouses"
      data-test="selectWarehouses"
      outlined
      dense
      small-chips
      deletable-chips
      clearable
      auto-select-first
      hide-selected
      multiple
      return-object
      :items="warehouseIds"
    ></v-select>
    <swMatrixProduct v-if="hasInventory" :products="matrix" :readonly="true" :show-eye="true" :hide-zeroes="true" :show-image="false">
      <template #left="{ product }">
        <div>
          <h2>{{ product.SKUS[0].articleCodeSupplier }}</h2>
          <h3>{{ product.SKUS[0].articleDescription }}</h3>
        </div>
      </template>
    </swMatrixProduct>
    <v-card v-else>
      <v-card-title>
        <h2>
          {{ swT('no_inventory') }}
        </h2>
      </v-card-title>
    </v-card>
    <v-row v-if="hasInventory" class="pt-3">
      <v-col align="right" class="pr-1">
        <v-btn class="mr-2" color="primary" @click.stop="refresh()">
          <v-icon x-large>mdi-sync</v-icon>
        </v-btn>
        <v-btn class="mr-2" color="primary" @click.stop="printBarcodes()">
          <v-icon x-large>mdi-barcode</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <swPrintDialog v-model="printDialog" :print-buffer="printBuffer" @close-print-dialog="printDialog = false" @ok="printDialog = false" />
  </div>
</template>

<script>
import print from '../functions/print'
import productFunctions from '../functions/products'
import swPrintDialog from '../components/swPrintDialog.vue'
import globalStore from '../store/globalStore'
import swMatrixProduct from '../components/swMatrixProduct.vue'
import { swT } from '@/functions/i18n'

export default {
  components: { swPrintDialog, swMatrixProduct },
  props: ['product'],
  data() {
    return {
      swT,
      printDialog: false,
      printBuffer: [],
      selectedWarehouses: [],
    }
  },
  computed: {
    warehouseIds() {
      return this.getItems('warehouseId')
    },
    inventory() {
      const inventory = globalStore.getLatestCollectionObject('inventory')
      const stock = []
      this.product.SKUS.forEach((sku) => {
        const warehouseDataByBarcodeMatch = inventory[sku.id]?.data

        if (warehouseDataByBarcodeMatch) {
          Object.entries(warehouseDataByBarcodeMatch).forEach(([warehouseId, qty]) => {
            stock.push({
              barcode: sku.id,
              qty: qty.qty,
              warehouse: this.warehouseIds.find((wh) => wh.value === warehouseId).text,
            })
          })
        }
      })
      return stock
    },
    matrix() {
      return productFunctions.buildMatrices(
        this.inventory,
        globalStore.getLatestCollectionObject('sku'),
        globalStore.getIndexedProducts(),
        (product) => product.SKUS.find((sku) => sku.GROUPED)?.GROUPED?.slice(-3, -1),
        globalStore.getMetadata().sizeOrdering.data,
        this.$store.getters.mapSubSizes,
        'warehouse',
        this.selectedWarehouses.map((wh) => wh.text)
      )
    },
    hasInventory() {
      return this.inventory.find((item) => item.qty)
    },
  },
  created() {
    this.selectedWarehouses = this.warehouseIds
  },
  methods: {
    async refresh() {
      await globalStore.getLatestCollectionAPI('inventory')?.pull()
      this._computedWatchers.inventory.run()
      this._computedWatchers.matrix.run()
      this.$forceUpdate()
    },
    printBarcodes() {
      const printBuffer = []
      const skus = globalStore.getLatestCollectionObject('sku')

      for (const item of this.inventory) {
        const sku = skus[item.barcode]
        if (item.qty > 0 && sku) printBuffer.push(print.sku4BarcodeLabel(sku, item.qty))
      }

      this.printBuffer = printBuffer
      this.printDialog = true
    },
    getItems(field) {
      return globalStore.getItems(field, this.$store)
    },
  },
}
</script>
