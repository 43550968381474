<template>
  <v-card :elevation="elevationRules(product)" class="staggerAnimation" :class="cardClass" style="width:150px;height:300px">
    <v-card-text :class="cardHeaderClass(product)" style="height: 36px;cursor: pointer;" class="px-1 py-0" :data-test="`card-header-${product.articleCode}`">
      <slot name="card-header" :product="product"></slot>
    </v-card-text>

    <v-card-text class="pa-0" :data-test="`card-image-${product.articleCode}`" style="cursor: pointer;">
      <slot name="card-image" :product="product"></slot>
    </v-card-text>

    <v-card-text class="pa-1 pt-0">
      <div class="d-flex flex-column justify-space-between">
        <span class="ellipsis">{{ product.articleDescription }}</span>
        <span class="ellipsis">{{ product.collection }}</span>
        <div class="font-weight-black">
          <swCurrency :amount="product.price" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['hover', 'product'],
  computed: {
    cardClass() {
      switch (this.product.source) {
        case 'tenant':
          return { 'warning-outline': true }
        case 'latestCollection':
          return this.product.usedByTenant ? { 'info-outline': true } : { 'green-outline': true }
        default:
          return { 'dark-grey-outline': true }
      }
    },
  },
  methods: {
    elevationRules(product) {
      return this.hover ? 20 : product.usedByTenant !== true ? 10 : 0
    },
    cardHeaderClass(product) {
      return {
        warning: product.source == 'tenant',
        info: product.usedByTenant,
        green: product.source == 'latestCollection',
      }
    },
  },
}
</script>

<style scoped>
/* 
warning: #fb8c00
info: #2196f3
green: #4caf50
*/

.info-outline {
  outline: 1px solid #2196f3;
}
.warning-outline {
  outline: 1px solid #fb8c00;
}
.green-outline {
  outline: 1px solid #4caf50;
}
.dark-grey-outline {
  outline: 1px solid #444;
}
.no-outline {
  outline: inherit;
}
.card-info {
  height: 64px;
}
.opacity {
  opacity: 0.8;
}
</style>
