<template>
  <v-dialog persistent :value="true" max-width="500" width="400px">
    <v-card>
      <v-card-title class="caption info white--text pa-1">
        <v-row no-gutters>
          <v-col class="ma-0 d-flex align-center">
            <v-icon class="white--text mr-1">mdi-barcode</v-icon>
            <span>{{ brand.count }}&nbsp;</span>
            <span v-if="brand.ownerTenantId !== $store.state.user.current_tenant && $store.state.user.current_tenant !== 'master'">/ {{ isAssociate }}</span>
            <span v-if="brand.ownerTenantId === $store.state.user.current_tenant">Owner</span>
          </v-col>

          <v-col class="d-flex align-center justify-end">
            <v-icon class="white--text">mdi-calendar</v-icon>
            {{ formatDate(brand.lasttime) }}
            <v-btn v-if="isUpdated" class="mx-2" fab dark x-small color="red" @click="$emit('operation', { updated: true })">
              <v-icon dark>mdi-exclamation</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row v-if="brand.url">
          <v-col class="py-lg-6">
            <v-img class="my-6" :src="brand.url" contain max-height="350px"></v-img>
          </v-col>
        </v-row>
        <div v-else>
          <v-row>
            <v-col cols="10" offset="1" md="8" offset-md="2" class="py-6">
              <div class="text-h4 text-center pb-6">{{ brand.name }}</div>
              <v-img src="../assets/barcode-logo.png" contain max-height="150px"></v-img>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <div style="max-height:484px;overflow:auto;">
        <div v-if="!hasAccess" class="ma-2">
          <v-btn
            style="width: 100%"
            large
            color="red"
            dark
            outlined
            data-test="branddata"
            @click.native="$emit('operation', { showRestrictedProducts: brand.collection, type: 'internal', brand: brand })"
          >
            <v-icon left>mdi-lock</v-icon>
            {{ swT('branddata') }}
          </v-btn>
        </div>
        <div class="ma-2 text-center">
          <v-btn style="width: 100%" large color="red" dark outlined data-test="showproducts" @click.native="$emit('operation', { showProducts: true })">
            <v-icon left>mdi-tag</v-icon>
            {{ swT('showproducts') }}
          </v-btn>
        </div>
        <swAuth role="products-admin,sw">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="goToSkuEdit(true)">
              <v-icon left>mdi-tag-plus</v-icon>
              {{ swT('new_product') }}
            </v-btn>
          </div>
        </swAuth>
        <swAuth role="logistics-receivings_user">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined data-test="receivingfrombrand" @click.native="$emit('operation', { navigateTo: receivingsRoute })">
              <v-icon left>mdi-tag</v-icon>
              {{ swT('receivings') }}
            </v-btn>
          </div>
        </swAuth>
        <swAuth role="products-purchase">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { navigateTo: PORoute })">
              <v-icon left>mdi-tag</v-icon>
              {{ swT('purchaseorder') }}
            </v-btn>
          </div>
        </swAuth>
        <swAuth role="products-admin,sw">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined data-test="skueditor" @click.native="goToSkuEdit()">
              <v-icon left>mdi-table-edit</v-icon>
              {{ swT('skueditor') }}
            </v-btn>
          </div>
        </swAuth>
        <swAuth role="products-admin,sw">
          <div v-for="dataProvider of Object.keys(brand.dataProviders || {})" :key="dataProvider" class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { showRestrictedProducts: dataProvider, type: 'external' })">
              <v-icon v-if="userHasDataProviderSubscription(dataProvider)" left>mdi-lock-open-variant</v-icon>
              <v-icon v-else left>mdi-lock</v-icon>
              {{ swT('dataprovider-show-' + dataProvider) }}
            </v-btn>
          </div>
        </swAuth>
        <swAuth role="products-report_user">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined data-test="articlestatusfrombrand" @click.native="$emit('operation', { articleStatus: true })">
              <v-icon left>mdi-chart-line</v-icon>
              {{ swT('articlestatus') }}
            </v-btn>
          </div>
        </swAuth>

        <swAuth role="products-report_user">
          <div class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined data-test="stockfrombrand" @click.native="$emit('operation', { stock: true })">
              <v-icon left>mdi-package-variant</v-icon>
              {{ swT('stock_report') }}
            </v-btn>
          </div>
        </swAuth>

        <div class="ma-2 text-center">
          <v-btn
            style="width: 100%"
            large
            color="red"
            dark
            outlined
            :data-test="`toggle-favorite-${isFavoriteBrand(brand)}`"
            @click.native="$emit('operation', { toggleFavorite: true })"
          >
            <v-icon left>{{ isFavoriteBrand(brand) ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
            {{ isFavoriteBrand(brand) ? swT('removefromfavorites') : swT('addtofavorites') }}
          </v-btn>
        </div>

        <div v-if="!$store.getters.isDemo" class="ma-2 text-center">
          <v-btn style="width: 100%" large color="red" dark outlined data-test="configurebrand" @click.native="$emit('operation', { configure: true })">
            <v-icon left>mdi-cog</v-icon>
            {{ swT('configure') }}
          </v-btn>
        </div>

        <swAuth role="sw">
          <div v-if="$store.state.metadata.brands.newBrands.includes(brand.name)" class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { toggleNew: true })">
              <v-icon left>mdi-upload</v-icon>
              {{ swT('removefromnew') }}
            </v-btn>
          </div>
          <div v-else class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { toggleNew: true })">
              <v-icon left>mdi-upload-outline</v-icon>
              {{ swT('addtonew') }}
            </v-btn>
          </div>
          <div v-if="$store.state.metadata.brands.popularBrands.includes(brand.name)" class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { togglePopular: true })">
              <v-icon left>mdi-thumb-up</v-icon>
              {{ swT('removefrompopular') }}
            </v-btn>
          </div>
          <div v-else class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { togglePopular: true })">
              <v-icon left>mdi-thumb-up-outline</v-icon>
              {{ swT('addtopopular') }}
            </v-btn>
          </div>
          <div v-if="$store.state.metadata.brands.trendingBrands.includes(brand.name)" class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { toggleTrending: true })">
              <v-icon left>mdi-arrow-up-bold-box</v-icon>
              {{ swT('removefromtrending') }}
            </v-btn>
          </div>
          <div v-else class="ma-2 text-center">
            <v-btn style="width: 100%" large color="red" dark outlined @click.native="$emit('operation', { toggleTrending: true })">
              <v-icon left>mdi-arrow-up-bold-box-outline</v-icon>
              {{ swT('addtotrending') }}
            </v-btn>
          </div>
        </swAuth>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" large text @click.native="$emit('operation', { cancel: true })">
          {{ swT('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import userFunctions from '../functions/users'
import { format } from 'date-fns'
import { hashBrand } from '@softwear/latestcollectioncore'

export default {
  props: ['brand'],
  data() {
    return {
      swT,
    }
  },
  computed: {
    isUpdated() {
      const brand = this.brand
      if (!this.$store.state.activeConfig.favorites.favoriteBrands.value.includes(brand.collection)) return false
      if (this.$route.query.feature !== 'brandupdated') return false
      if (!brand.lastSync) return true
      if (brand.lastSync < brand.lasttime) return true
      return false
    },
    hasAccess() {
      const isOwner = this.brand.ownerTenantId === this.$store.state.user.current_tenant
      if (isOwner) return true
      if (!this.brand.associates) return true
      const isApprovedAssociate = this.brand.associates?.find((associate) => associate.tenantId === this.$store.state.user.current_tenant && associate.status === 'approved')
      if (isApprovedAssociate) return true
      return false
    },
    isAssociate() {
      const isAssociate = this.brand.associates?.find((associate) => associate.tenantId === this.$store.state.user.current_tenant)
      if (!Array.isArray(this.brand.associates) || isAssociate) {
        return isAssociate?.status || 'Public'
      } else {
        return isAssociate?.status || 'Private'
      }
    },
    receivingsRoute() {
      return { path: 'receivings', query: { brand: hashBrand(this.brand.collection) } }
    },
    PORoute() {
      return { path: 'purchaseorders', query: { brand: hashBrand(this.brand.collection) } }
    },
  },
  deactivated() {
    this.$emit('close-brand-popup')
    this.$destroy()
  },
  methods: {
    isFavoriteBrand(brand) {
      return this.$store.state.activeConfig.favorites.favoriteBrands.value.includes(hashBrand(brand.name))
    },
    async goToSkuEdit(newSku = false) {
      this.$emit('operation', { cancel: true })
      // Load brand sku data so that skuEditor can retrieve it.
      // TODO: refactor skuEditor to get an optional brandId parameter for this purpose
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) {
        await this.$store.dispatch('loadBrand', { brand: this.brand.collection, dataProvider: '' })
      }
      if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) {
        await this.$store.dispatch('loadBrand', { brand: this.brand.collection, dataProvider: this.$store.state.user.latestCollectionSettings.dataProviderAdmin })
      }

      if (newSku) this.$router.push({ path: 'skueditor', query: { brand: this.brand.collection, newSku: true } })
      else this.$router.push({ path: 'skueditor', query: { brand: this.brand.collection } })
    },
    formatDate(date) {
      if (!date) return ''
      return format(new Date(date), 'MM-dd-yy HH:mm')
    },
    userHasDataProviderSubscription(dataProvider) {
      return userFunctions.hasDataProviderSubscription(dataProvider, this.$store.state)
    },
  },
}
</script>

<style scoped>
.pointerCursor {
  cursor: pointer;
}
</style>
